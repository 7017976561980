<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 16.5001V15.5001L2 13.5001V16.5001C2 16.7834 1.90417 17.0209 1.7125 17.2126C1.52083 17.4043 1.28333 17.5001 1 17.5001C0.716667 17.5001 0.479167 17.4043 0.2875 17.2126C0.0958333 17.0209 0 16.7834 0 16.5001ZM4 16.5001V11.5001L6 9.50011V16.5001C6 16.7834 5.90417 17.0209 5.7125 17.2126C5.52083 17.4043 5.28333 17.5001 5 17.5001C4.71667 17.5001 4.47917 17.4043 4.2875 17.2126C4.09583 17.0209 4 16.7834 4 16.5001ZM8 16.5001V9.50011L10 11.5251V16.5001C10 16.7834 9.90417 17.0209 9.7125 17.2126C9.52083 17.4043 9.28333 17.5001 9 17.5001C8.71667 17.5001 8.47917 17.4043 8.2875 17.2126C8.09583 17.0209 8 16.7834 8 16.5001ZM12 16.5001V11.5251L14 9.52511V16.5001C14 16.7834 13.9042 17.0209 13.7125 17.2126C13.5208 17.4043 13.2833 17.5001 13 17.5001C12.7167 17.5001 12.4792 17.4043 12.2875 17.2126C12.0958 17.0209 12 16.7834 12 16.5001ZM16 16.5001V7.50011L18 5.50011V16.5001C18 16.7834 17.9042 17.0209 17.7125 17.2126C17.5208 17.4043 17.2833 17.5001 17 17.5001C16.7167 17.5001 16.4792 17.4043 16.2875 17.2126C16.0958 17.0209 16 16.7834 16 16.5001ZM0.3 9.20011L5.575 3.92511C5.95833 3.54178 6.43333 3.35011 7 3.35011C7.56667 3.35011 8.04167 3.54178 8.425 3.92511L11 6.50011L16.3 1.20011C16.5 1.00011 16.7375 0.904276 17.0125 0.912609C17.2875 0.920943 17.525 1.02511 17.725 1.22511C17.9083 1.42511 17.9958 1.66261 17.9875 1.93761C17.9792 2.21261 17.8833 2.44178 17.7 2.62511L12.425 7.90011C12.0417 8.28344 11.5667 8.47511 11 8.47511C10.4333 8.47511 9.95833 8.28344 9.575 7.90011L7 5.32511L1.7 10.6251C1.5 10.8251 1.2625 10.9209 0.9875 10.9126C0.7125 10.9043 0.475 10.8001 0.275 10.6001C0.0916667 10.4001 0.00416667 10.1626 0.0125 9.88761C0.0208333 9.61261 0.116667 9.38344 0.3 9.20011Z" fill="#0696FB" />
  </svg>
</template>

