<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8.33333 6.66667H11.6667V5.41667C11.6667 3.80583 12.9725 2.5 14.5833 2.5C16.1942 2.5 17.5 3.80583 17.5 5.41667C17.5 7.0275 16.1942 8.33333 14.5833 8.33333H13.3333V11.6667H14.5833C16.1942 11.6667 17.5 12.9725 17.5 14.5833C17.5 16.1942 16.1942 17.5 14.5833 17.5C12.9725 17.5 11.6667 16.1942 11.6667 14.5833V13.3333H8.33333V14.5833C8.33333 16.1942 7.0275 17.5 5.41667 17.5C3.80583 17.5 2.5 16.1942 2.5 14.5833C2.5 12.9725 3.80583 11.6667 5.41667 11.6667H6.66667V8.33333H5.41667C3.80583 8.33333 2.5 7.0275 2.5 5.41667C2.5 3.80583 3.80583 2.5 5.41667 2.5C7.0275 2.5 8.33333 3.80583 8.33333 5.41667V6.66667ZM6.66667 6.66667V5.41667C6.66667 4.72631 6.10702 4.16667 5.41667 4.16667C4.72631 4.16667 4.16667 4.72631 4.16667 5.41667C4.16667 6.10702 4.72631 6.66667 5.41667 6.66667H6.66667ZM6.66667 13.3333H5.41667C4.72631 13.3333 4.16667 13.893 4.16667 14.5833C4.16667 15.2737 4.72631 15.8333 5.41667 15.8333C6.10702 15.8333 6.66667 15.2737 6.66667 14.5833V13.3333ZM13.3333 6.66667H14.5833C15.2737 6.66667 15.8333 6.10702 15.8333 5.41667C15.8333 4.72631 15.2737 4.16667 14.5833 4.16667C13.893 4.16667 13.3333 4.72631 13.3333 5.41667V6.66667ZM13.3333 13.3333V14.5833C13.3333 15.2737 13.893 15.8333 14.5833 15.8333C15.2737 15.8333 15.8333 15.2737 15.8333 14.5833C15.8333 13.893 15.2737 13.3333 14.5833 13.3333H13.3333ZM8.33333 8.33333V11.6667H11.6667V8.33333H8.33333Z" fill="currentColor" />
  </svg>
</template>
