<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="currentColor"
  >
    <!-- Trigger Button -->
    <rect
      x="6"
      y="8"
      width="12"
      height="8"
      rx="2"
    />
    <!-- Payload Box -->
    <rect x="10" y="4" width="4" height="4" />
    <!-- Trigger Button Handle -->
    <circle cx="9" cy="10" r="1" />
  </svg>
</template>
