<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="currentColor"
  >
    <!-- Circle with play button inside to represent action -->
    <path stroke-linecap="round" stroke-linejoin="round" d="M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
    <path stroke-linecap="round" stroke-linejoin="round" d="M15.91 11.672a.375.375 0 0 1 0 .656l-5.603 3.113a.375.375 0 0 1-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112Z" />
    
    <!-- Additional lines or strokes to suggest motion or tasks being executed -->
    <path stroke-linecap="round" stroke-linejoin="round" d="M3 21l3.5-3.5" />
    <path stroke-linecap="round" stroke-linejoin="round" d="M3 17l5-5" />
  </svg>
</template>
