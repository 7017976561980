<template>
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1.75396 18.5C1.27162 18.5 0.858563 18.3281 0.514787 17.9843C0.171596 17.6411 0 17.2284 0 16.746V4.46833C0 3.98599 0.171596 3.57294 0.514787 3.22916C0.858563 2.88597 1.27162 2.71437 1.75396 2.71437H9.581L7.82704 4.46833H1.75396V16.746H14.0317V10.651L15.7856 8.89708V16.746C15.7856 17.2284 15.614 17.6411 15.2708 17.9843C14.9271 18.3281 14.514 18.5 14.0317 18.5H1.75396ZM11.5542 3.21864L12.8039 4.44641L7.01583 10.2345V11.4842H8.2436L14.0536 5.67418L15.3033 6.90195L8.98904 13.2381H5.26188V9.51096L11.5542 3.21864ZM15.3033 6.90195L11.5542 3.21864L13.7466 1.02619C14.0974 0.675396 14.5178 0.5 15.0077 0.5C15.4971 0.5 15.9099 0.675396 16.246 1.02619L17.4738 2.27588C17.81 2.61206 17.9781 3.02132 17.9781 3.50365C17.9781 3.98599 17.81 4.39525 17.4738 4.73142L15.3033 6.90195Z" fill="currentColor" />
  </svg>
</template>
