<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6.81659 8.26671C6.91659 8.36671 7.03325 8.41671 7.16659 8.41671C7.29992 8.41671 7.41659 8.36671 7.51659 8.26671L10.6333 5.15004C10.7221 5.05819 10.7666 4.94911 10.7666 4.82282C10.7666 4.69652 10.7194 4.58337 10.6249 4.48337C10.5305 4.38337 10.414 4.33337 10.2755 4.33337C10.137 4.33337 10.0173 4.38337 9.91659 4.48337L7.18325 7.23337L5.89992 5.95004C5.81103 5.86115 5.69992 5.81671 5.56659 5.81671C5.43325 5.81671 5.31659 5.86671 5.21659 5.96671C5.11659 6.06671 5.06659 6.18337 5.06659 6.31671C5.06659 6.45004 5.11659 6.56671 5.21659 6.66671L6.81659 8.26671ZM3.33325 11.6667C3.06659 11.6667 2.83325 11.5667 2.63325 11.3667C2.43325 11.1667 2.33325 10.9334 2.33325 10.6667V1.33337C2.33325 1.06671 2.43325 0.833374 2.63325 0.633374C2.83325 0.433374 3.06659 0.333374 3.33325 0.333374H12.6666C12.9333 0.333374 13.1666 0.433374 13.3666 0.633374C13.5666 0.833374 13.6666 1.06671 13.6666 1.33337V10.6667C13.6666 10.9334 13.5666 11.1667 13.3666 11.3667C13.1666 11.5667 12.9333 11.6667 12.6666 11.6667H3.33325ZM3.33325 10.6667H12.6666V1.33337H3.33325V10.6667ZM1.33325 13.6667C1.06659 13.6667 0.833252 13.5667 0.633252 13.3667C0.433252 13.1667 0.333252 12.9334 0.333252 12.6667V2.83337C0.333252 2.69171 0.381446 2.57296 0.477835 2.47712C0.574235 2.38129 0.69368 2.33337 0.836169 2.33337C0.978669 2.33337 1.09714 2.38129 1.19159 2.47712C1.28603 2.57296 1.33325 2.69171 1.33325 2.83337V12.6667H11.1666C11.3083 12.6667 11.427 12.7149 11.5228 12.8113C11.6187 12.9077 11.6666 13.0271 11.6666 13.1696C11.6666 13.3121 11.6187 13.4306 11.5228 13.525C11.427 13.6195 11.3083 13.6667 11.1666 13.6667H1.33325Z" fill="currentColor" />
  </svg>
</template>
