export const gifs = {
  actions: {
    setGif({ commit }, data) {
      commit("setGif", data);
    },
    setGifTag({ commit }, data) {
      commit("setGifTag", data);
    },
  },

  mutations: {
    setGif(state, data) {
      state.gif = data;
    },
    setGifTag(state, data) {
      state.gifTag = data;
    },
  },

  state: () => ({
    gif: {},
    gifTag: "",
  }),
};
