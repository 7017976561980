<template>
  <div>
    <!-- Static sidebar for desktop -->
    <div class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4">
        <div class="flex h-16 shrink-0 items-center">
          <MIcon icon="IconMomentumLogo" class="h-10 w-auto" />
        </div>
        <nav class="flex flex-1 flex-col">
          <ul class="flex flex-1 flex-col gap-y-7">
            <li>
              <ul class="-mx-2 space-y-1">
                <li>
                  <!-- Current: "bg-gray-800 text-white", Default: "text-gray-400 hover:text-white hover:bg-gray-800" -->
                  <router-link :to="{ name: 'momentum-support-organization', params: { organizationId: state.organizationId }}" class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6" :class="[renderActiveLink('momentum-support-organization')]">
                    <MIcon icon="IconUsers" class="h-6 w-6 shrink-0" />
                    Users
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'momentum-support-organization-service-accounts', params: { organizationId: state.organizationId }}" class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6" :class="[renderActiveLink('momentum-support-organization-service-accounts')]">
                    <MIcon icon="IconCollection" class="h-6 w-6 shrink-0" />
                    Service Accounts
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'momentum-support-organization-logs', params: { organizationId: state.organizationId } }" class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6" :class="[renderActiveLink('momentum-support-organization-logs')]">
                    <MIcon icon="IconListBulleted" class="h-6 w-6 shrink-0" />
                    Audit Logs
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'momentum-support-organization-workflow-runs', params: { organizationId: state.organizationId } }" class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6" :class="[renderActiveLink('momentum-support-organization-workflow-runs')]">
                    <MIcon icon="IconNavaidVhfor" class="h-6 w-6 shrink-0" />
                    Workflow Runs
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'momentum-support-organization-assist-definitions', params: { organizationId: state.organizationId } }" class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6" :class="[renderActiveLink('momentum-support-organization-assist-definitions')]">
                    <MIcon icon="IconDealAssist" class="h-6 w-6 shrink-0" />
                    Workflows (Assist)
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'momentum-support-organization-digest-definitions', params: { organizationId: state.organizationId } }" class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6" :class="[renderActiveLink('momentum-support-organization-digest-definitions')]">
                    <MIcon icon="IconDigest" class="h-6 w-6 shrink-0" />
                    Digests
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'momentum-support-organization-extraction-raw-sets', params: { organizationId: state.organizationId } }" class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6" :class="[renderActiveLink('momentum-support-organization-extraction-raw-sets')]">
                    <MIcon icon="IconInboxTray" class="h-6 w-6 shrink-0" />
                    Extractions
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'momentum-support-organization-meetings', params: { organizationId: state.organizationId } }" class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6" :class="[renderActiveLink('momentum-support-organization-meetings')]">
                    <MIcon icon="IconCalendarDays" class="h-6 w-6 shrink-0" />
                    Meetings
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'momentum-support-organization-ai-tasks', params: { organizationId: state.organizationId } }" class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6" :class="[renderActiveLink('momentum-support-organization-ai-task-overview')]">
                    <MIcon icon="IconNavaidVhfor" class="h-6 w-6 shrink-0" />
                    AI Tasks
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'momentum-support-organization-settings', params: { organizationId: state.organizationId } }" class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6" :class="[renderActiveLink('momentum-support-organization-settings')]">
                    <MIcon icon="IconIosSettings" class="h-6 w-6 shrink-0" />
                    Settings
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'momentum-support-organization-feature-flags', params: { organizationId: state.organizationId } }" class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6" :class="[renderActiveLink('momentum-support-organization-feature-flags')]">
                    <MIcon icon="IconFlag" class="h-6 w-6 shrink-0" />
                    Feature Settings
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'momentum-support-organization-feed-payloads', params: { organizationId: state.organizationId } }" class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6" :class="[renderActiveLink('momentum-support-organization-feed-payloads')]">
                    <MIcon icon="IconInboxTray" class="h-6 w-6 shrink-0" />
                    Feed Payloads
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'momentum-support-organization-assist-trigger-payloads', params: { organizationId: state.organizationId } }" class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6" :class="[renderActiveLink('momentum-support-organization-assist-trigger-payloads')]">
                    <MIcon icon="IconAssistTriggerPayload" class="h-6 w-6 shrink-0" />
                    Assist Trigger Payloads
                  </router-link>
                </li>
                <li>
                  <router-link
                    :to="{
                      name: 'momentum-support-organization-salesforce-metadata-objects',
                      params: { organizationId: state.organizationId },
                    }"
                    class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                    :class="[renderActiveLink('momentum-support-organization-salesforce-metadata-objects')]"
                  >
                    <MIcon icon="IconAssistTriggerPayload" class="h-6 w-6 shrink-0" />
                    Salesforce Metadata Objects
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'momentum-support-organization-action-runners', params: { organizationId: state.organizationId } }" class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6" :class="[renderActiveLink('momentum-support-organization-action-runners')]">
                    <MIcon icon="IconActionRunners" class="h-6 w-6 shrink-0" />
                    Action Runners
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'momentum-support-organization-domains', params: { organizationId: state.organizationId } }" class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6" :class="[renderActiveLink('momentum-support-organization-action-runners')]">
                    <MIcon icon="IconDocumentMagnify" class="h-6 w-6 shrink-0" />
                    Domains
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'momentum-support-organization-coaching-aggregates', params: { organizationId: state.organizationId } }" class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6" :class="[renderActiveLink('momentum-support-organization-coaching')]">
                    <MIcon icon="IconNavaidVhfor" class="h-6 w-6 shrink-0" />
                    Coaching
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'momentum-support-organization-backfills', params: { organizationId: state.organizationId }}" class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6" :class="[renderActiveLink('momentum-support-organization-backfills')]">
                    <MIcon icon="IconReply" class="h-6 w-6 shrink-0" />
                    Backfills
                  </router-link>
                </li>
              </ul>
            </li>

            <li class="mt-auto">
              <router-link :to="{ name: 'momentum-support-home'}" class="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white">
                <MIcon icon="IconAccountBalanceOutlined" class="h-6 w-6 shrink-0" />
                Organizations
              </router-link>
            </li>
          </ul>
        </nav>
        <PopUpNotifications />
      </div>
    </div>

    <div class="lg:pl-72">
      <main>
        <div v-show="organizationDeletedAt === null" class="px-4 sm:px-6 lg:px-8">
          <h1 class="text-3xl font-semibold text-gray-900 py-2" v-text="organizationName" />
          <div class="py-5">
            <router-view />
          </div>
        </div>

        <div v-show="organizationDeletedAt" class="mx-auto max-w-6xl py-10 sm:px-6 lg:px-8">
          <p class="text-center text-xl text-red-600">
            This organization was deleted
          </p>
          <p class="text-center" v-text="organizationDeletedAt ? new Date(organizationDeletedAt).toISOString() : null" />
        </div>
      </main>
    </div>
  </div>
</template>

<script lang="ts">
import { ApolloProvider } from "@vue/apollo-option";
import { computed, defineComponent, inject, onMounted, reactive } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import { OrganizationApi } from "../../api/organization";
import PopUpNotifications from "../../components/notifications/PopUpNotifications.vue";
import MIcon from "../../components/reusable-components/MIcon.vue";
export default defineComponent({
  components: {
    MIcon,
    PopUpNotifications,
  },
  setup() {
    type TState = {
      organizationId: number;
    };

    const apollo: ApolloProvider = inject<ApolloProvider>("apollo");
    const store: any = useStore();
    const route: any = useRoute();
    const getOrganizationApi = new OrganizationApi(apollo);
    const organizationName = computed(() => store.state["momentum-support"]?.organization?.name);
    const organizationDeletedAt = computed(() => store.state["momentum-support"]?.organization?.deleted_at);
    const organizationId = route.params.organizationId;
    const state: TState = reactive({
      organizationId: organizationId,
    });

    onMounted(() => {
      getOrganization();
    });

    async function getOrganization(): Promise<void> {
      const organization = await getOrganizationApi.getOrganizationById(state.organizationId);
      store.dispatch("momentum-support/setOrganization", { ...organization });
    }

    function renderActiveLink(routeName: string): string {
      return routeName === route.name ? "bg-gray-800 text-white" : "text-gray-400 hover:text-white hover:bg-gray-800";
    }

    return { organizationDeletedAt, organizationName, renderActiveLink, state };
  },
});
</script>
