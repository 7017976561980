<template>
  <div>
    <slot />
    <PopUpNotifications />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import PopUpNotifications from "../components/notifications/PopUpNotifications.vue";

export default defineComponent({
  components: {
    PopUpNotifications,
  },
});
</script>
