import { AccessRoleEnum, SupportRoleEnum, TAuthUser, UserRoleEnum } from "../api/user/types";
import store from "../store/index";

export const canRoleUseAdminPage = (authUser?: TAuthUser): boolean | undefined =>
  isAtLeastRoleEditor(authUser) || isAtLeastRoleUserAdmin(authUser);

export const isExactRoleUser = (authUser?: TAuthUser): boolean | undefined => isRole(authUser, UserRoleEnum.USER);

export const isAtLeastRoleUser = (authUser?: TAuthUser): boolean | undefined =>
  isRole(authUser, UserRoleEnum.USER) || isAtLeastRoleEditor(authUser);

export const isAtLeastRoleEditor = (authUser?: TAuthUser): boolean | undefined =>
  isRole(authUser, UserRoleEnum.EDITOR) || isExactRoleOrganizationAdmin(authUser);

export const isAtLeastRoleUserAdmin = (authUser?: TAuthUser): boolean | undefined =>
  isRole(authUser, UserRoleEnum.USER_ADMIN) || isExactRoleOrganizationAdmin(authUser);

export const isExactRoleUserAdmin = (authUser?: TAuthUser): boolean | undefined =>
  isRole(authUser, UserRoleEnum.USER_ADMIN);

export const isExactRoleOrganizationAdmin = (authUser?: TAuthUser): boolean | undefined =>
  isRole(authUser, UserRoleEnum.ORGANIZATION_ADMIN);

export const isExactRoleMomentumSupport = (authUser?: TAuthUser): boolean | undefined =>
  isRole(authUser, SupportRoleEnum.MOMENTUM_SUPPORT);

function isRole(givenAuthUser: TAuthUser, role: AccessRoleEnum): boolean | undefined {
  const authUser = givenAuthUser ?? store.state.authUser;
  if (!authUser) {
    // Return undefined when the authUser is not yet initialized inside store
    return undefined;
  }
  return authUser.role === role;
}
