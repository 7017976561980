<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="currentColor"
  >
    <!-- Inbox Tray Shape -->
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M3 12v6a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-6"
    />
    <!-- Top Tray Edge -->
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M3 12h5l2 3h4l2-3h5"
    />
    <!-- Arrow Indicating Data Input -->
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M12 3v8m0 0l-3-3m3 3l3-3"
    />
  </svg>
</template>
