<template>
  <div class="h-screen flex overflow-hidden bg-gradient-to-r bg-white">
    <!-- dashboard contents here -->
    <transition name="fade">
      <SidebarMobile v-if="showSideBar">
        <!-- set sidebar nav here -->
        <SidebarNav />
      </SidebarMobile>
    </transition>

    <transition name="slide-fade">
      <SidebarDesktop v-if="showSideBar">
        <SidebarNav />
      </SidebarDesktop>
    </transition>
    <DashHeader>
      <DashBody>
        <router-view />
      </DashBody>
    </DashHeader>
    <PopUpNotifications />
  </div>
</template>

<script lang="ts">
import { computed, ComputedRef, defineComponent, onMounted, watch } from "vue";
import { Store, useStore } from "vuex";

import DashBody from "../components/dashboard/DashBody.vue";
import DashHeader from "../components/dashboard/DashHeader.vue";
import SidebarDesktop from "../components/dashboard/SidebarDesktop.vue";
import SidebarMobile from "../components/dashboard/SidebarMobile.vue";
import SidebarNav from "../components/dashboard/SidebarNav.vue";
import PopUpNotifications from "../components/notifications/PopUpNotifications.vue";
import { identifyUser } from "../utils/common-js";

export default defineComponent({
  components: {
    DashBody,
    DashHeader,
    PopUpNotifications,
    SidebarDesktop,
    SidebarMobile,
    SidebarNav,
  },
  setup() {
    const store: Store<any> = useStore();
    onMounted(() => {
      identifyUser(store.state.authUser);
    });

    watch(
      () => store.state.authUser,
      (value) => {
        identifyUser(value);
      }
    );

    const showSideBar: ComputedRef<boolean> = computed(() => store.state.showSideBar);
    return { showSideBar };
  },
});
</script>

<style>
html {
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif;
  font-size: 16px;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}
</style>
