export const meeting = {
  actions: {
    setActionItem({ commit }, data) {
      commit("setActionItem", data);
    },
    setAutopilot({ commit }, data) {
      commit("setAutopilot", data);
    },
    setOpportunity({ commit }, data) {
      commit("setOpportunity", data);
    },
    setOrganizationUsers({ commit }, data) {
      commit("setOrganizationUsers", data);
    },
  },

  mutations: {
    setActionItem(state, data) {
      state.actionItem = data;
    },
    setAutopilot(state, data) {
      state.autopilot = data;
    },
    setOpportunity(state, data) {
      state.opportunity = data;
    },
    setOrganizationUsers(state, data) {
      state.organizationUsers = data;
    },
  },

  state: () => ({
    actionItem: null,
    autopilot: null,
    opportunity: null,
    organizationUsers: null,
  }),
};
