<template>
  <div>
    <NConfigProvider :theme-overrides="themeOverrides">
      <AppLayout>
        <router-view />
      </AppLayout>
    </NConfigProvider>
    <CConfirmAction />
  </div>
</template>

<script lang="ts">
import { ApolloProvider } from "@vue/apollo-option";
import { NConfigProvider } from "naive-ui";
import { defineComponent, ref, watch } from "vue";
import { Router, useRouter } from "vue-router";
import { Store, useStore } from "vuex";

import { FeatureSetApi } from "./api/feature-set/api";
import { UserApi } from "./api/user/api";
import { TUser } from "./api/user/types";
import { CConfirmAction } from "./components/library";
import AppLayout from "./layouts/AppLayout.vue";
import { useFeaturesStore } from "./store/features";
import { isAtLeastRoleEditor } from "./utils/roles";
/**
 * Use this for type hints under js file
 * @type import('naive-ui').GlobalThemeOverrides
 */

export const themeOverrides = {
  Input: {
    border: "1px solid rgb(107 114 128)",
    borderFocus: "2px solid rgb(37, 99, 235)",
    borderHover: "1px solid rgb(37, 99, 235)",
    caretColor: "#000000FF",
    // boxShadowFocus: "0 0 0 2px rgba(28, 122, 196, 0.2)",
  },
  Tabs: {
    barColor: "#2080F0FF",
    tabTextColorActiveBar: "#2080F0FF",
    tabTextColorHoverBar: "#2080F0FF",
  },
  Tooltip: {
    color: "#FFF",
    textColor: "#000",
  },
  // ...
};
export default defineComponent({
  components: {
    AppLayout,
    CConfirmAction,
    NConfigProvider,
  },
  name: "App",
  setup() {
    const store: Store<any> = useStore();
    const router: Router = useRouter();
    const isOffline = ref(false);
    let userApi: UserApi = null;

    watch(
      () => store.state.authUser,
      async (authUser) => {
        if (!authUser?.userId || userApi) {
          return;
        }

        userApi = new UserApi(store.state.apollo);
        await fetchProfileDetails(authUser.userId, store.state.apollo);
        const featureSetApi = new FeatureSetApi(store.state.apollo);
        const features = await featureSetApi.getAll();
        const featuresStore = useFeaturesStore();
        featuresStore.setAllFeatures(features);
        if (isOffline.value) {
          redirectToOfflinePage();
        }
      },
      {
        immediate: true,
      }
    );

    function redirectToOfflinePage(): void {
      router.push({ name: "offline" });
    }

    async function fetchProfileDetails(userId: number, apollo: ApolloProvider): Promise<void> {
      if (!userId || !apollo) {
        return;
      }
      let user: TUser = null;
      if (isAtLeastRoleEditor(store.state.authUser)) {
        user = await userApi.get(userId);
        setProfileInStore(user);
      } else {
        user = await userApi.getInfo(userId);
        setProfileInStore(user);
      }
    }

    function setProfileInStore(user: TUser): void {
      store.dispatch("setProfile", user);
    }

    return {
      themeOverrides,
    };
  },
});
</script>

<style>
body {
  @apply bg-gray-100;
}
</style>
