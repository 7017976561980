import { IntegrationServiceEnum } from "../types/integrations";

type IntegrationStore = {
  isOrganizationIntegrationAuthenticated: {
    [service: string]: boolean;
  };
};
export const integrations = {
  actions: {
    setIsOrganizationIntegrationAuthenticated(
      { commit },
      { isAuthenticated, service }: { isAuthenticated: boolean; service: IntegrationServiceEnum }
    ) {
      commit("setIsOrganizationIntegrationAuthenticated", {
        isAuthenticated,
        service,
      });
    },
  },
  getters: {
    isOrganizationIntegrationAuthenticated: (state) => (service: IntegrationServiceEnum) => {
      return state.isOrganizationIntegrationAuthenticated[service];
    },
  },
  mutations: {
    setIsOrganizationIntegrationAuthenticated(
      state,
      { isAuthenticated, service }: { isAuthenticated: boolean; service: IntegrationServiceEnum }
    ) {
      state.isOrganizationIntegrationAuthenticated[service] = isAuthenticated;
    },
  },
  state: (): IntegrationStore => ({
    isOrganizationIntegrationAuthenticated: {},
  }),
};
