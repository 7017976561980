import { watch } from "vue";

import { TAlertType } from "../alert/types";
import { useConfirmActionStore } from "./store";

export function confirmAction(
  message: string,
  options?: { alertMessage?: string; alertType?: TAlertType; description?: string }
): Promise<boolean> {
  const { setConfirmingAction, state } = useConfirmActionStore();
  return new Promise((resolve) => {
    setConfirmingAction(message, options);
    watch(
      () => state.confirmingAction,
      (confirmingAction) => {
        if (!confirmingAction) {
          resolve(state.isConfirmed);
        }
      }
    );
  });
}
