export const validations = {
  actions: {
    checkValidations({ commit }) {
      commit("validate");
    },
    clearErrors({ commit }) {
      commit("clearErrors");
    },
    hasMadeChanges({ commit }, status) {
      commit("madeChanges", status);
    },
    removeInvalidError({ commit }, variable) {
      commit("removeInvalidError", variable);
    },
    setInvalidError({ commit }, variable) {
      commit("setInvalidError", variable);
    },
    setInvalidErrors({ commit }, errors) {
      commit("setInvalidErrors", errors);
    },
  },

  mutations: {
    clearErrors(state) {
      state.formComponentErrors = [];
    },
    madeChanges(state, status) {
      state.madeChanges = status;
    },
    removeInvalidError(state, variable) {
      const index = state.formComponentErrors.indexOf(variable);
      if (index > -1) {
        state.formComponentErrors.splice(index, 1);
      }
    },
    setInvalidError(state, variable) {
      if (!state.formComponentErrors.some((value) => value === variable)) {
        const errors = [...state.formComponentErrors, variable];
        state.formComponentErrors = errors;
      }
    },
    setInvalidErrors(state, errors) {
      state.invalidErrors = errors;
    },
    validate(state) {
      state.validate += 1;
    },
  },

  state: () => ({
    formComponentErrors: [],
    invalidErrors: [],
    madeChanges: false,
    validate: 0,
  }),
};
