export function findHighestIndex(): number {
  const elements = document.querySelectorAll("*");
  const zIndexElements = Array.from(elements).filter(
    (element: HTMLElement) =>
      element.style.zIndex !== "" &&
      !["pop-up-notifications-container", "pop-up-notification-item"].includes(element?.className)
  );
  let maxZIndex = 0;

  zIndexElements.forEach((element: HTMLElement) => {
    const zIndex = parseInt(element.style.zIndex || "0", 10);
    if (zIndex > maxZIndex) {
      maxZIndex = zIndex;
    }
  });
  return maxZIndex;
}

export function setZIndex(renderIndex: boolean): string {
  if (!renderIndex) {
    return "";
  }

  const highestIndex = findHighestIndex();
  return `z-index: ${highestIndex + 1};`;
}
