<template>
  <svg
    width="18"
    height="14"
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6.49996 7.83331L6.49996 9.49998C6.49996 9.73609 6.57982 9.93401 6.73954 10.0937C6.89927 10.2535 7.09718 10.3333 7.33329 10.3333C7.5694 10.3333 7.76732 10.2535 7.92704 10.0937C8.08677 9.93401 8.16663 9.73609 8.16663 9.49998V7.83331L9.83329 7.83331C10.0694 7.83331 10.2673 7.75345 10.427 7.59373C10.5868 7.43401 10.6666 7.23609 10.6666 6.99998C10.6666 6.76387 10.5868 6.56595 10.427 6.40623C10.2673 6.24651 10.0694 6.16665 9.83329 6.16665L8.16663 6.16665V4.49998C8.16663 4.26387 8.08677 4.06595 7.92704 3.90623C7.76732 3.74651 7.5694 3.66665 7.33329 3.66665C7.09718 3.66665 6.89927 3.74651 6.73954 3.90623C6.57982 4.06595 6.49996 4.26387 6.49996 4.49998V6.16665H4.83329C4.59718 6.16665 4.39927 6.24651 4.23954 6.40623C4.07982 6.56595 3.99996 6.76387 3.99996 6.99998C3.99996 7.23609 4.07982 7.43401 4.23954 7.59373C4.39927 7.75345 4.59718 7.83331 4.83329 7.83331H6.49996ZM2.33329 13.6666C1.87496 13.6666 1.4826 13.5035 1.15621 13.1771C0.82982 12.8507 0.666626 12.4583 0.666626 12L0.666626 1.99998C0.666626 1.54165 0.82982 1.14929 1.15621 0.822896C1.4826 0.496507 1.87496 0.333313 2.33329 0.333313L12.3333 0.333313C12.7916 0.333313 13.184 0.496507 13.5104 0.822896C13.8368 1.14929 14 1.54165 14 1.99998V5.74998L16.625 3.12498C16.7638 2.98609 16.9166 2.95137 17.0833 3.02081C17.25 3.09026 17.3333 3.2222 17.3333 3.41665V10.5833C17.3333 10.7778 17.25 10.9097 17.0833 10.9791C16.9166 11.0486 16.7638 11.0139 16.625 10.875L14 8.24998V12C14 12.4583 13.8368 12.8507 13.5104 13.1771C13.184 13.5035 12.7916 13.6666 12.3333 13.6666H2.33329Z" fill="#1D496D" />
  </svg>
</template>
