import { initialize, LDClient } from "launchdarkly-js-client-sdk";
import { LDContext } from "launchdarkly-js-sdk-common";

import { TAuthUser } from "../api/user/types";

const launchDarklyId: string = import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_ID;

const defaultValue = false;

export async function getEnabledFeature(featureName: string, authUser: TAuthUser): Promise<boolean> {
  if (!featureName || !authUser) {
    return false;
  }

  if (authUser.isMomentumSupport) {
    // Turn on all feature flags for Momentum Support users;
    return true;
  }

  const ldUser: LDContext = {
    custom: { organizationId: authUser.organizationId },
    // Get feature-flags for impersonated user (profile.email), not authenticated user (authUser.email)
    email: authUser.email,
    key: String(authUser.userId),
  };

  const ldClient: LDClient = initialize(launchDarklyId, ldUser);
  await ldClient.waitForInitialization();

  return ldClient.variation(featureName, defaultValue);
}
