<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.30773 18H8.33658V5.99998H4.30773C4.21798 5.99998 4.14426 6.02882 4.08656 6.08652C4.02886 6.14423 4.00001 6.21795 4.00001 6.3077V17.6923C4.00001 17.782 4.02886 17.8557 4.08656 17.9134C4.14426 17.9711 4.21798 18 4.30773 18ZM9.83653 18H14.1635V5.99998H9.83653V18ZM15.6634 18H19.6923C19.782 18 19.8558 17.9711 19.9135 17.9134C19.9712 17.8557 20 17.782 20 17.6923V6.3077C20 6.21795 19.9712 6.14423 19.9135 6.08652C19.8558 6.02882 19.782 5.99998 19.6923 5.99998H15.6634V18ZM4.30773 19.5C3.8026 19.5 3.37503 19.325 3.02503 18.975C2.67503 18.625 2.50003 18.1974 2.50003 17.6923V6.3077C2.50003 5.80257 2.67503 5.375 3.02503 5.025C3.37503 4.675 3.8026 4.5 4.30773 4.5H19.6923C20.1974 4.5 20.625 4.675 20.975 5.025C21.325 5.375 21.5 5.80257 21.5 6.3077V17.6923C21.5 18.1974 21.325 18.625 20.975 18.975C20.625 19.325 20.1974 19.5 19.6923 19.5H4.30773Z" fill="#4B5563" />
  </svg>
</template>
