import { TSalesforceObjectNodeFilter } from "./types";
export function addFilters(filters: TSalesforceObjectNodeFilter[]): string {
  if (!filters?.length) {
    return "";
  }

  const filtersString: string[] = [];
  filters.forEach((filter) => {
    filtersString.push(`{ field: "${filter.field}", operator: "${filter.operator}", value: "${filter.value}" }`);
  });
  return ", " + filtersString.join(", ");
}
