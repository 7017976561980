<template>
  <svg
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.5 10.8C4.245 10.8 4.0311 10.7136 3.8583 10.5408C3.6861 10.3686 3.6 10.155 3.6 9.9C3.6 9.645 3.6861 9.4311 3.8583 9.2583C4.0311 9.0861 4.245 9 4.5 9C4.755 9 4.9689 9.0861 5.1417 9.2583C5.3139 9.4311 5.4 9.645 5.4 9.9C5.4 10.155 5.3139 10.3686 5.1417 10.5408C4.9689 10.7136 4.755 10.8 4.5 10.8ZM8.1 10.8C7.845 10.8 7.6314 10.7136 7.4592 10.5408C7.2864 10.3686 7.2 10.155 7.2 9.9C7.2 9.645 7.2864 9.4311 7.4592 9.2583C7.6314 9.0861 7.845 9 8.1 9C8.355 9 8.5689 9.0861 8.7417 9.2583C8.9139 9.4311 9 9.645 9 9.9C9 10.155 8.9139 10.3686 8.7417 10.5408C8.5689 10.7136 8.355 10.8 8.1 10.8ZM11.7 10.8C11.445 10.8 11.2314 10.7136 11.0592 10.5408C10.8864 10.3686 10.8 10.155 10.8 9.9C10.8 9.645 10.8864 9.4311 11.0592 9.2583C11.2314 9.0861 11.445 9 11.7 9C11.955 9 12.1686 9.0861 12.3408 9.2583C12.5136 9.4311 12.6 9.645 12.6 9.9C12.6 10.155 12.5136 10.3686 12.3408 10.5408C12.1686 10.7136 11.955 10.8 11.7 10.8ZM1.8 18C1.305 18 0.8811 17.8239 0.5283 17.4717C0.1761 17.1189 0 16.695 0 16.2V3.6C0 3.105 0.1761 2.6814 0.5283 2.3292C0.8811 1.9764 1.305 1.8 1.8 1.8H2.7V0H4.5V1.8H11.7V0H13.5V1.8H14.4C14.895 1.8 15.3189 1.9764 15.6717 2.3292C16.0239 2.6814 16.2 3.105 16.2 3.6V16.2C16.2 16.695 16.0239 17.1189 15.6717 17.4717C15.3189 17.8239 14.895 18 14.4 18H1.8ZM1.8 16.2H14.4V7.2H1.8V16.2ZM1.8 5.4H14.4V3.6H1.8V5.4Z" fill="currentColor" />
  </svg>
</template>
