<template>
  <div class="flex items-center text-xs bg-gradient-to-r from-cyan-500 to-teal-400 text-white text-center px-2 py-1 rounded-full border border-white">
    <IconLightBulb class="w-4 h-4 text-white" color="white" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import IconLightBulb from "./IconLightBulb.vue";

export default defineComponent({
  components: {
    IconLightBulb,
  },

  setup() {},
});
</script>
