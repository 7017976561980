<template>
  <div>
    <!-- this self-closing div creates the backdrop for index based modal -->
    <div v-if="show && (type === 'index_based')" :style="setZIndex(show)" class="fixed inset-0 bg-black/40 transition-opacity" aria-hidden="true" />
    <dialog
      id="dialog-element"
      ref="dialogElement"
      class="bg-transparent lg:w-full w-[90%] overflow-hidden"
      :class="[renderModalSize(), addIndexBasedModalStyling()]"
      :style="setZIndex(show)"
    >
      <Transition name="slide-up">
        <section v-if="show">
          <div class="relative bg-transparent bg-white rounded-lg text-left transition-all w-full flex flex-col max-h-[95vh]">
            <!-- Header - Fixed -->
            <div class="flex-shrink-0 px-6 md:px-10 pt-4 pb-2 ">
              <div class="py-2 flex items-center justify-between">
                <CHeading :title="props.title" />
                <div>
                  <slot name="extra-header" />
                </div>
              </div>
              <p class="text-gray-400">
                <slot name="secondary-header" />
              </p>
            </div>
  
            <!-- Body - Scrollable -->
            <div class="flex-1 overflow-y-auto px-6 md:px-10 py-4 min-h-0">
              <slot />
            </div>
  
            <!-- Footer - Fixed -->
            <div class="flex-shrink-0 px-6 md:px-10 pb-6 pt-4">
              <slot name="footer">
                <div class="flex items-center justify-end">
                  <div class="flex items-center">
                    <CButton
                      v-show="!buttonOptions?.close?.hide"
                      id="modal-cancel"
                      type="button"
                      name="secondaryButton"
                      @click="cancelDialog"
                    >
                      <span v-text="buttonOptions?.close?.text || 'Cancel'" />
                    </CButton>
                    <CButton
                      v-show="!buttonOptions?.submit?.hide"
                      id="modal-submit"
                      type="button"
                      name="primaryButton"
                      class="ml-4"
                      :loading="buttonOptions?.submit?.loading"
                      :disabled="buttonOptions?.submit?.disabled"
                      @click="submitDialog"
                    >
                      <span v-text="buttonOptions?.submit?.text || 'Submit'" />
                    </CButton>
                  </div>
                </div>
              </slot>
            </div>
          </div>
        </section>
      </Transition>
    </dialog>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, onUnmounted, PropType, ref, watch } from "vue";

import CButton from "../button/CButton.vue";
import CHeading from "../heading/CHeading.vue";
import { setZIndex } from "../utilities";
import { TModalButtonOptions, TModalSize } from "./types";

const props = defineProps({
  buttonOptions: {
    default: null,
    type: Object as PropType<TModalButtonOptions>,
  },
  show: {
    default: false,
    required: true,
    type: Boolean,
  },
  size: {
    default: "large",
    type: String as PropType<TModalSize>,
  },
  title: {
    default: "",
    type: String,
  },
  type: {
    default: "index_based",
    type: String as PropType<"modal" | "index_based">,
  },
});

const emit = defineEmits(["close", "cancel", "submit"]);
const dialogElement = ref<HTMLDialogElement | null>(null);

onMounted(() => {
  showDialog();
  dialogElement.value.addEventListener("close", closeDialog);
  document.addEventListener("keydown", handKeyDownEvent);
});

onUnmounted(() => {
  document.removeEventListener("close", closeDialog);
  document.removeEventListener("keydown", handKeyDownEvent);
});

watch(
  () => props.show,
  () => {
    showDialog();
  }
);

function renderModalSize(): string {
  switch (props.size) {
    case "small":
      return "sm:max-w-md";
    case "medium":
      return "sm:max-w-lg";
    case "large":
      return "sm:max-w-2xl";
    case "x-large":
      return "sm:max-w-3xl";
    case "2x-large":
      return "sm:max-w-4xl";
    case "3x-large":
      return "sm:max-w-5xl";
  }
}

function handKeyDownEvent(event: KeyboardEvent): void {
  if (event.key === "Escape") {
    cancelDialog();
  }
}

function cancelDialog(): void {
  emit("cancel", true);
}

function closeDialog(): void {
  emit("close", true);
}

function showDialog(): void {
  if (props.show && props.type === "modal") {
    dialogElement.value?.showModal();
  } else if (props.show && props.type === "index_based") {
    dialogElement.value?.show();
  } else {
    dialogElement.value?.close();
  }
}

function addIndexBasedModalStyling(): string {
  if (props.type === "index_based") {
    return "fixed inset-0";
  }
}

function submitDialog(): void {
  emit("submit", true);
}
</script>

<style>
.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.1s ease-out;
}

.slide-up-enter-from {
  opacity: 0;
  transform: translateY(30px);
}

.slide-up-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
dialog::backdrop {
  background-color: rgba(0, 0, 0, 0.4);
}
</style>
