import { TSelectUserOption, TUser, UserRoleEnum } from "./types";

//transform the user data to the format that the UI select component expects
export function transformMomentumUser(user: TUser[]): TSelectUserOption[] {
  return user.map((user) => {
    return {
      disabled: false,
      id: user.slackUserId,
      label: user.fullName || user.slackUserProfile.real_name,
      momentumUser: user,
      momentumUserId: user.id,
      type: "user",
      value: user.slackUserId,
    };
  });
}

export const userRoleNames: Record<UserRoleEnum, string> = {
  [UserRoleEnum.EDITOR]: "Editor",
  [UserRoleEnum.ORGANIZATION_ADMIN]: "Organization Admin",
  [UserRoleEnum.USER]: "User",
  [UserRoleEnum.USER_ADMIN]: "User Admin",
};
