<template>
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4 20.5C3.45 20.5 2.97933 20.3043 2.588 19.913C2.196 19.521 2 19.05 2 18.5V6.5C2 5.95 2.196 5.47933 2.588 5.088C2.97933 4.696 3.45 4.5 4 4.5H16C16.55 4.5 17.021 4.696 17.413 5.088C17.8043 5.47933 18 5.95 18 6.5V11L21.15 7.85C21.3167 7.68333 21.5 7.64167 21.7 7.725C21.9 7.80833 22 7.96667 22 8.2V16.8C22 17.0333 21.9 17.1917 21.7 17.275C21.5 17.3583 21.3167 17.3167 21.15 17.15L18 14V18.5C18 19.05 17.8043 19.521 17.413 19.913C17.021 20.3043 16.55 20.5 16 20.5H4ZM4 18.5H16V6.5H4V18.5Z" fill="currentColor" />
  </svg>
</template>
