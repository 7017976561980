<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    enable-background="new 0 0 512 512"
    xml:space="preserve"
    fill="currentColor"
  >
    <path d="M415.6,434h-5.1c-6.5,0-12-0.9-12.7-7.4c0,0,0-0.1,0-0.1C383.2,290.6,297.6,288,297.6,256s85.7-34.6,100.2-170.5l0-0.1c0.7-6.5,6.2-7.4,12.7-7.4h5.1c7.5,0,14-5.8,14.4-13.3c0.4-8-6-14.7-14-14.7L96.4,50c-7.5,0-14,5.8-14.4,13.3c-0.4,8,6,14.7,14,14.7h5.5c6.5,0,12,0.9,12.7,7.4l0,0.1C128.8,221.4,214.4,224,214.4,256s-85.7,34.6-100.2,170.5l0,0.1c-0.7,6.5-6.2,7.4-12.7,7.4h-5.1c-7.5,0-14,5.8-14.4,13.3c-0.4,8,6,14.7,14,14.7h320c8,0,14.4-6.6,14-14.7C429.6,439.8,423.1,434,415.6,434z M163.3,399.8c28.7-79.8,79.6-70.1,79.6-101.6c0-31.2,0-39.3,0-55.6c0-19.4-36.8-32.9-59.8-64.4c-3.8-5.2,0-12.4,6.5-12.4h132.9c6.5,0,10.4,7,6.7,12.2c-22.6,31.6-60.1,45.2-60.1,64.6c0,17.9,0,24.4,0,55.6c0,31.2,48.9,22.7,79.8,101.6c2.8,7.1,0.7,16-6.9,16H170.2C162.5,415.8,160.7,407,163.3,399.8z" />
  </svg>
</template>
