import { FetchResult, Observable } from "@apollo/client";
import { DocumentNode } from "graphql";
import gql from "graphql-tag";

import { BaseApi } from "../base";
import {
  TGqlIntegrationOrganizationStatuses,
  TIntegration,
  TOrgIntegrations,
  TSlackWorkspace,
  TUserIntegrationStatus,
} from "./types";

export class IntegrationsApi extends BaseApi {
  async getSalesforceInstance(): Promise<{ endpoint: string }> {
    type TData = { organization: { integrations: { endpoint: string }[] }[] };
    const query: DocumentNode = gql`
      query GetSalesforceInstance {
        organization {
          integrations(where: { service: { _eq: "SALESFORCE" } }) {
            endpoint
          }
        }
      }
    `;

    try {
      const result = await this.query<TData, null>(query);
      return result.organization[0].integrations[0];
    } catch (error) {
      this.handleError(error);
    }
  }

  async integrationSignOut(service: string): Promise<boolean> {
    type TVariables = { service: string };
    type TData = { momentum: { integration: { signOut: { accepted: boolean } } } };

    const mutation: DocumentNode = gql`
      mutation IntegrationSignOut($service: String!) {
        momentum {
          integration {
            signOut(service: $service) {
              accepted
            }
          }
        }
      }
    `;

    try {
      const result = await this.mutate<TData, TVariables>(mutation, { service });
      return result.momentum.integration.signOut.accepted;
    } catch (error) {
      this.handleError(error);
    }
  }

  subscribeToUserIntegrationsStatuses(
    userId: number
  ): Observable<FetchResult<{ user_integration: TUserIntegrationStatus[] }>> {
    type TVariables = { userId: number };
    type TData = { user_integration: TUserIntegrationStatus[] };

    const query: DocumentNode = gql`
      subscription UserIntegrationsStatuses($userId: Int!) {
        user_integration(where: { userId: { _eq: $userId } }) {
          service
          status
        }
      }
    `;

    return this.subscribe<TData, TVariables>(query, { userId }, { errorPolicy: "all", fetchPolicy: "no-cache" });
  }

  async getOrganizationIntegrations(): Promise<TOrgIntegrations[]> {
    type TVariables = {};
    type TData = { user_integration: TOrgIntegrations[] };

    const query: DocumentNode = gql`
      query GetOrganizationIntegrations {
        user_integration {
          service
          status
          useForOrganization
          updatedAt
          user {
            email
          }
        }
      }
    `;

    try {
      const result: TData = await this.query<TData, TVariables>(query);
      return result.user_integration;
    } catch (error) {
      this.handleError(error);
    }
  }

  async getSlackWorkspace(): Promise<TSlackWorkspace[]> {
    type TVariables = {};
    type TData = { slack_workspace: TSlackWorkspace[] };

    const query: DocumentNode = gql`
      query GetSlackWorkspace {
        slack_workspace {
          teamID
          updatedAt
        }
      }
    `;

    try {
      const result: TData = await this.query<TData, TVariables>(query);
      return result.slack_workspace;
    } catch (error) {
      this.handleError(error);
    }
  }

  async isServiceConnectedForOrganization(service: string): Promise<{ status: string }[]> {
    type TVariables = { service: string };
    type TData = { user_integration: { status: string }[] };

    const query: DocumentNode = gql`
      query ServiceIsConnectedForOrganization($service: String!) {
        user_integration(
          where: { useForOrganization: { _eq: true }, service: { _eq: $service }, status: { _eq: "AUTHENTICATED" } }
        ) {
          status
        }
      }
    `;

    try {
      const result: TData = await this.query<TData, TVariables>(query, { service });
      return result.user_integration;
    } catch (error) {
      this.handleError(error);
    }
  }

  async getUserIntegrations(userId: number): Promise<TIntegration[]> {
    type TVariables = { userId: number };
    type TData = { user_integration: TIntegration[] };

    const query: DocumentNode = gql`
      query GetUserIntegrations($userId: Int!) {
        user_integration(where: { userId: { _eq: $userId } }) {
          service
          status
          useForOrganization
          updatedAt
        }
      }
    `;

    try {
      const result: TData = await this.query<TData, TVariables>(query, { userId });
      return result.user_integration;
    } catch (error) {
      this.handleError(error);
    }
  }

  async getOrganizationIntegrationStatuses(): Promise<TGqlIntegrationOrganizationStatuses> {
    type TData = {
      momentum: { integration: { organizationStatuses: TGqlIntegrationOrganizationStatuses } };
    };
    const query: DocumentNode = gql`
      query GetOrganizationIntegrationStatuses {
        momentum {
          integration {
            organizationStatuses
          }
        }
      }
    `;

    try {
      const result: TData = await this.query<TData, null>(query);
      return result.momentum.integration.organizationStatuses;
    } catch (error) {
      this.handleError(error);
    }
  }
}
