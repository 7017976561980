<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" rx="16" fill="#F3F4F6" />
    <path d="M11.7783 18.0038C11.7768 18.4192 11.6036 18.8171 11.2965 19.1108C10.9894 19.4044 10.5734 19.5699 10.1392 19.5712C9.70484 19.5699 9.28868 19.4043 8.98156 19.1105C8.67444 18.8167 8.50132 18.4186 8.5 18.003C8.5 17.1396 9.23667 16.4341 10.1392 16.4341H11.7792V18.003L11.7783 18.0038ZM12.605 18.0038C12.605 17.1404 13.3417 16.4349 14.2442 16.4349C15.1467 16.4349 15.8842 17.1404 15.8842 18.0038V21.9318C15.8828 22.3475 15.7096 22.7457 15.4023 23.0396C15.095 23.3334 14.6786 23.4989 14.2442 23.5C13.8098 23.4987 13.3937 23.3331 13.0866 23.0393C12.7794 22.7455 12.6063 22.3473 12.605 21.9318V18.0038Z" fill="#DF1D5A" />
    <path d="M14.2442 11.6363C13.81 11.6349 13.394 11.4691 13.0871 11.1753C12.7802 10.8816 12.6072 10.4835 12.6058 10.0682C12.6058 9.20476 13.3425 8.5 14.245 8.5C15.1475 8.5 15.885 9.20476 15.885 10.0682V11.6371L14.2442 11.6363ZM14.2442 12.4272C15.1467 12.4272 15.8842 13.1319 15.8842 13.9953C15.8831 14.4111 15.7099 14.8096 15.4026 15.1036C15.0953 15.3976 14.6788 15.5633 14.2442 15.5643H10.1392C9.70469 15.563 9.28841 15.3973 8.98126 15.1033C8.67412 14.8093 8.5011 14.411 8.5 13.9953C8.5 13.1319 9.23667 12.4272 10.1392 12.4272H14.245H14.2442Z" fill="#36C5F1" />
    <path d="M20.9 13.9953C20.9 13.1319 21.6375 12.4272 22.54 12.4272C23.4425 12.4272 24.1792 13.1319 24.1792 13.9953C24.1781 14.411 24.0051 14.8093 23.6979 15.1033C23.3908 15.3973 22.9745 15.563 22.54 15.5643H20.9V13.9953ZM20.0734 13.9953C20.0723 14.411 19.8992 14.8093 19.5921 15.1033C19.285 15.3973 18.8687 15.563 18.4342 15.5643C17.9996 15.5633 17.5831 15.3976 17.2758 15.1036C16.9684 14.8096 16.7953 14.4111 16.7942 13.9953V10.0682C16.7942 9.20476 17.5317 8.5 18.4342 8.5C19.3367 8.5 20.0734 9.20476 20.0734 10.0682V13.9953Z" fill="#2DB67E" />
    <path d="M18.4342 20.3629C19.3367 20.3629 20.0734 21.0684 20.0734 21.9318C20.072 22.3473 19.8989 22.7455 19.5918 23.0393C19.2847 23.3331 18.8685 23.4987 18.4342 23.5C17.9997 23.4989 17.5833 23.3334 17.276 23.0396C16.9687 22.7457 16.7955 22.3475 16.7942 21.9318V20.3629H18.4342ZM18.4342 19.572C17.9997 19.5709 17.5833 19.4054 17.276 19.1116C16.9687 18.8177 16.7955 18.4195 16.7942 18.0038C16.7942 17.1404 17.5317 16.4349 18.4342 16.4349H22.54C23.4425 16.4349 24.1792 17.1404 24.1792 18.0038C24.1779 18.4194 24.0048 18.8175 23.6976 19.1113C23.3905 19.4051 22.9744 19.5707 22.54 19.572H18.4342Z" fill="#EBB22D" />
  </svg>
</template>
