<template>
  <svg
    width="12"
    height="9"
    viewBox="0 0 12 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2.98125 4.00003L4.67292 5.6917C4.78959 5.80836 4.84549 5.94447 4.84063 6.10003C4.83577 6.25558 4.775 6.3917 4.65834 6.50836C4.54167 6.61531 4.40556 6.67121 4.25 6.67607C4.09445 6.68093 3.95834 6.62503 3.84167 6.50836L1.15834 3.82503C1.04167 3.70836 0.983337 3.57225 0.983337 3.4167C0.983337 3.26114 1.04167 3.12503 1.15834 3.00836L3.84167 0.325028C3.94862 0.218084 4.0823 0.164612 4.24271 0.164612C4.40313 0.164612 4.54167 0.218084 4.65834 0.325028C4.775 0.441695 4.83334 0.580237 4.83334 0.740654C4.83334 0.90107 4.775 1.03961 4.65834 1.15628L2.98125 2.83336H8.33334C9.14028 2.83336 9.82813 3.11774 10.3969 3.68649C10.9656 4.25524 11.25 4.94308 11.25 5.75003V7.50003C11.25 7.66531 11.1941 7.80385 11.0823 7.91565C10.9705 8.02746 10.8319 8.08336 10.6667 8.08336C10.5014 8.08336 10.3629 8.02746 10.251 7.91565C10.1392 7.80385 10.0833 7.66531 10.0833 7.50003V5.75003C10.0833 5.26392 9.9132 4.85072 9.57292 4.51045C9.23264 4.17017 8.81945 4.00003 8.33334 4.00003H2.98125Z" fill="currentColor" /></svg>
</template>
