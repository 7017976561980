import {
  Centrifuge,
  ClientEvents,
  Subscription,
  SubscriptionEvents,
  SubscriptionOptions,
  SubscriptionTokenContext,
} from "centrifuge";

import { isLocalDevEnvironment, momentumApiUrl } from "./import-env-vars";

let centrifuge: Centrifuge | null = null;

export function initPushNotifications(token: string): void {
  if (centrifuge) {
    return;
  }
  let endpoint = import.meta.env.VITE_PUSH_NOTIFICATION_WEBSOCKET_ENDPOINT;
  if (!endpoint && isLocalDevEnvironment) {
    endpoint = "ws://localhost:8005/connection/websocket";
  }
  if (!endpoint) {
    console.error("PushNotificationController: No endpoint provided for Centrifuge");
    return;
  }
  try {
    centrifuge = new Centrifuge(endpoint);
    const eventNames: (keyof ClientEvents)[] = [
      "connecting",
      "connected",
      "disconnected",
      "message",
      "error",
      "subscribed",
      "subscribing",
      "unsubscribed",
      "publication",
      "join",
      "leave",
    ];
    eventNames.forEach((eventName: keyof ClientEvents) =>
      centrifuge.addListener(eventName, (context: unknown) => {
        if (eventName === "error") {
          console.error("PushNotificationController", context);
        } else {
          console.log(`PushNotificationController: ${eventName}`, context);
        }
      })
    );
    centrifuge.setToken(token);
    centrifuge.connect();
  } catch (error) {
    console.error("Failed to initialize Centrifuge", error);
  }
}

export type PushNotificationSubscriptionListeners = Pick<SubscriptionEvents, "publication">;

export async function subscribeToPushNotificationChannelOfUser(
  authToken: string,
  userId: number,
  listeners: PushNotificationSubscriptionListeners
): Promise<void> {
  await subscribeToPushNotificationChannel(authToken, `user#${userId}`, listeners);
}

export async function subscribeToPushNotificationChannelOfEntity(
  organizationId: number,
  authToken: string,
  entityType: string,
  entityId: number | string,
  listeners: PushNotificationSubscriptionListeners
): Promise<void> {
  const channel = `org-${organizationId}#entity.${entityType}.${entityId}`;
  await subscribeToPushNotificationChannel(authToken, channel, listeners);
}

export async function subscribeToPushNotificationChannel(
  authToken: string,
  channel: string,
  listeners: PushNotificationSubscriptionListeners
): Promise<void> {
  if (!centrifuge) {
    console.error("Centrifuge not initialized");
    return;
  }
  if (channel in centrifuge.subscriptions()) {
    return;
  }
  try {
    const subscription: Subscription = centrifuge.newSubscription(channel, {
      token: await getSubscriptionToken(authToken)({ channel }),
    });
    if (!subscription) {
      console.error(`Failed to create subscription for channel "${channel}"`);
      return;
    }
    if (listeners.publication) {
      subscription.addListener("publication", listeners.publication);
    }
    subscription.subscribe();
    await subscription.ready(10_000);
  } catch (error) {
    console.error(`Failed to subscribe to push notification channel "${channel}"`, error);
    return;
  }
}

function getSubscriptionToken(authToken: string): SubscriptionOptions["getToken"] {
  return async (ctx: SubscriptionTokenContext): Promise<string> => {
    const headers = new Headers();
    headers.set("Authorization", `Bearer ${authToken}`);
    headers.set("Content-Type", "application/json; charset=utf-8");
    const response: Response = await fetch(`${momentumApiUrl}/push-notification/subscription-token`, {
      body: JSON.stringify(ctx),
      headers,
      method: "POST",
    });
    if (response.status > 200) {
      console.warn(`Failed to get subscription token: ${response.status} ${response.statusText}`);
      return "";
    }
    const token = (await response.json())?.token;
    if (!token) {
      throw new Error("Failed to get subscription token");
    }
    return token;
  };
}
