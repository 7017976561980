export const wizards = {
  actions: {
    setBox({ commit }, boxContent) {
      commit("setBox", boxContent);
    },
    setEditorForm({ commit }, editorForm) {
      commit("setEditorForm", editorForm);
    },
  },

  mutations: {
    setBox(state, value) {
      state.newBox = value;
    },
    setEditorForm(state, value) {
      state.editorForm = value;
    },
  },

  state: () => ({
    editorForm: null,
    newBox: null,
  }),
};
