/* eslint-disable no-unused-vars */
import { TUser } from "../../api/user/types";
import { TActionTypesEnum, TScheduleOptions } from "../../types/action-types";
import { TFlowFilter } from "../../types/condition-builder";
import { TSalesforceFlow, TSalesforceOutboundMessage } from "../../types/salesforce";
import { FlowTriggerActionTypes, TTriggerType } from "../../types/triggers";

export type TGetWorkflowsByCategoryVariables = {
  categoryWhere: { _eq: AssistDefinitionCategoryEnum };
  createdByIdWhere: { _in: number[] } | {};
  limit: number;
  nameWhere: { _ilike: string } | {};
  statusWhere: { _in: boolean[] } | {};
  tagsWhere: { _contains: string[] } | {};
  triggerTypeWhere: { _in: string[] } | {};
};

export enum AssistDefinitionCategoryEnum {
  AI_CALL_NOTIFICATION = "ai_call_notification",
  AI_NOTIFICATION = "ai_notification",
  AI_SIGNAL = "ai_signal",
  APPROVAL_ADVANCED = "approval_advanced",
  APPROVAL_CUSTOM = "approval_custom",
  AUTO_CREATE_ROOMS = "auto_create_rooms",
  AUTOPILOT_EXTRACTIONS = "autopilot_extractions",
  CONTACT_AUTOMATION = "contact_automation",
  DEALROOM_AUTO_INVITE = "dealroom_auto_invite",
  DEALROOM_RENAME = "dealroom_rename",
  DEFAULT = "default",
  EXECUTIVE_BRIEF = "executive_brief",
  ONBOARDING = "onboarding",
  OPPORTUNITY_NOTIFICATION = "opportunity_notification",
  SCHEDULED_NOTIFICATION = "scheduled_notification",
  SCHEDULED_REPORT = "scheduled_report",
  SIMPLE_TASK = "simple_task",
}

export interface TAssistDefinition {
  category: AssistDefinitionCategoryEnum;
  createdAt?: Date;
  createdBy?: TUser;
  deletedAt?: Date | null;
  description?: string;
  enabled?: boolean;
  error?: string;
  id?: number;
  isInternal?: boolean;
  lastExecutionAt?: Date;
  name: string;
  numberOfExecutions?: number;
  tags?: string[];
  triggerType: TTriggerType;
  updatedAt?: Date;
  wizard: TAssistDefinitionWizard | TAssistDefinitionMetadataWizard;
  wizardMetadata?: Record<string, any>;
}

export type TAssistDefinitionMetadataWizard = {
  formSchema?: { uiSettings?: Record<string, any>; variables?: any[] };
  jsonEditor: TAssistDefinitionWizard;
  template?: Record<string, any>;
  uiSettings?: Record<string, any>;
  version: "V2";
};

export type TAssistDefinitionWizard = {
  additionalObjects?: TAdditionalObjects;
  condition?: string;
  conditionAdditionalObjects?: TAdditionalObjects;
  cron?: string;
  cronTimezone?: string;
  salesforceFlow?: TSalesforceFlowDefinition;
  steps: Record<string, TStep> | TStep[];
  trigger: TTriggerType;
  version?: "2";
};

export type TSalesforceFlowDefinition = {
  condition?: TSalesforceFlowCondition;
  objectName: string;
  outboundMessageFieldNames?: string[];
  recordTriggerType: FlowTriggerActionTypes;
  scheduleOptions?: TScheduleOptions;
};

type TAdditionalObjects = Partial<Record<"SALESFORCE" | "MOMENTUM", string[]>>;

type TFilterFormula = {
  filterFormula: string;
  filterLogic?: never;
  filters?: never;
};

type TFilterLogic = {
  filterFormula?: never;
  filterLogic: string;
  filters: TFlowFilter[];
};

export type TSalesforceFlowCondition =
  | TFilterFormula
  | TFilterLogic
  | (Partial<TFilterLogic> & Partial<TFilterFormula>);

export type TStep = {
  args: Record<string, any>;
  condition?: {
    predicate: string;
    type: "run" | "skip";
  };
  continueOnHalted?: boolean;
  description?: string;
  footerOptions?: TFooterOptions;
  name?: string;
  ref?: string;
  routes?: string[];
  slaSchedule?: TSLASchedule;
  type: TActionTypesEnum;
};

export type TSLASchedule = {
  notifications: TNotification[];
  slaMinutes: number;
};

export type TNotification = {
  receiver: {
    slackChannelId?: string;
    slackUserId?: string;
    type: string;
  };
  reminderMinutes: number;
  strategy: string;
};

export type TFooterOptions = {
  allowAcknowledgeSLA?: boolean;
  dividerType?: string;
  showCreator?: boolean;
  showExecutionId?: boolean;
  showLastSent?: boolean;
  showLiveMode?: boolean;
  showSLAMessage?: boolean;
  showSourceLink?: boolean;
};

export type TSalesforceFlowDetailsForSupport = {
  flow: TSalesforceFlow & { Url: string };
  latestEventTime: string | null;
  outboundMessage: TSalesforceOutboundMessage & { Url: string };
};

export function renderRecordSteps(steps: Record<string, TStep> | TStep[]): Record<string, TStep> {
  if (!Array.isArray(steps)) {
    console.error("This is an unexpected format of steps");
  }
  return steps as Record<string, TStep>;
}
