export const momentum_support = {
  actions: {
    reset({ commit }) {
      commit("reset");
    },
    setOrganization({ commit }, data) {
      commit("setOrganization", data);
    },
    setOrganizationName({ commit }, name) {
      commit("setOrganizationName", name);
    },
    setOrganizations({ commit }, data) {
      commit("setOrganizations", data);
    },
    setOrganizationUsers({ commit }, data) {
      commit("setOrganizationUsers", data);
    },
  },

  mutations: {
    reset(state) {
      state.errors = [];
      state.organization = null;
      state.organizationFetched = null;
      state.organizationsFetched = null;
      state.organizations = [];
      state.organizationUsersFetched = null;
      state.organizationUsers = [];
    },
    setOrganization(state, organization) {
      state.organization = organization;
      state.organizationFetched = true;
    },
    setOrganizationName(state, name) {
      state.organization.name = name;
    },
    setOrganizations(state, organizations) {
      state.organizations = organizations;
      state.organizationsFetched = true;
    },
    setOrganizationUsers(state, organizationUsers) {
      state.organizationUsers = organizationUsers;
      state.organizationUsersFetched = true;
    },
  },

  state: () => ({
    errors: [],
    organization: null,
    organizationFetched: null,
    organizations: [],
    organizationsFetched: null,
    organizationUsers: [],
    organizationUsersFetched: null,
  }),
};
