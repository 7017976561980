import { defineStore } from "pinia";
import { reactive } from "vue";

import { TFeatureSet } from "../api/feature-set/types";

type TState = {
  features: TFeatureSet;
};

export const useFeaturesStore = defineStore("features", () => {
  const state: TState = reactive({
    features: {},
  });

  function setAllFeatures(features: TFeatureSet): void {
    state.features = features;
  }

  return { setAllFeatures, state };
});
