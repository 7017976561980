<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8.25 13.5C8.0375 13.5 7.8595 13.428 7.716 13.284C7.572 13.1405 7.5 12.9625 7.5 12.75C7.5 12.5375 7.572 12.3595 7.716 12.216C7.8595 12.072 8.0375 12 8.25 12H9.75C9.9625 12 10.1408 12.072 10.2847 12.216C10.4283 12.3595 10.5 12.5375 10.5 12.75C10.5 12.9625 10.4283 13.1405 10.2847 13.284C10.1408 13.428 9.9625 13.5 9.75 13.5H8.25ZM3 6C2.7875 6 2.6095 5.92825 2.466 5.78475C2.322 5.64075 2.25 5.4625 2.25 5.25C2.25 5.0375 2.322 4.85925 2.466 4.71525C2.6095 4.57175 2.7875 4.5 3 4.5H15C15.2125 4.5 15.3905 4.57175 15.534 4.71525C15.678 4.85925 15.75 5.0375 15.75 5.25C15.75 5.4625 15.678 5.64075 15.534 5.78475C15.3905 5.92825 15.2125 6 15 6H3ZM5.25 9.75C5.0375 9.75 4.85925 9.678 4.71525 9.534C4.57175 9.3905 4.5 9.2125 4.5 9C4.5 8.7875 4.57175 8.60925 4.71525 8.46525C4.85925 8.32175 5.0375 8.25 5.25 8.25H12.75C12.9625 8.25 13.1405 8.32175 13.284 8.46525C13.428 8.60925 13.5 8.7875 13.5 9C13.5 9.2125 13.428 9.3905 13.284 9.534C13.1405 9.678 12.9625 9.75 12.75 9.75H5.25Z" fill="#19212C" />
  </svg>
</template>
