<template>
  <div class="rounded-md p-4" :class="[renderBackgroundColor(type)]" data-test="alert-container">
    <div class="flex">
      <div v-if="showIcon" class="flex-shrink-0">
        <MIcon 
          :icon="iconMap[type]" 
          :data-test="`alert-${type}`" 
          :class="`h-5 w-5 text-${colorMap[type]}-400`" 
        />
      </div>
      <div class="ml-3 w-full">
        <div v-show="title || $slots['extra-header']" class="flex justify-between">
          <h3
            v-show="title"
            class="text-sm font-medium"
            :class="[renderTitleTextColor(type)]"
            data-test="alert-title"
            v-text="title"
          />
          <slot name="extra-header" />
        </div>
        <div class="text-sm w-full" :class="[renderBodyTextColor(type), title || $slots['extra-header'] ? 'mt-2' : '']" data-test="alert-body">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { PropType } from "vue";

import MIcon from "../../reusable-components/MIcon.vue";
import { TAlertColorMap, TAlertIconMap, TAlertType } from "./types";

defineProps({
  showIcon: {
    default: true,
    type: Boolean,
  },
  title: {
    default: "",
    type: String,
  },
  type: {
    default: "success",
    required: true,
    type: String as PropType<TAlertType>,
  },
});

const iconMap: TAlertIconMap = {
  error: "IconXClose",
  info: "IconInformationCircle",
  success: "IconCheck",
  warning: "IconWarning",
};

const colorMap: TAlertColorMap = {
  error: "red",
  info: "blue",
  success: "green",
  warning: "yellow",
};

function renderBackgroundColor(type: TAlertType): string {
  return `bg-${colorMap[type]}-50`;
}

function renderTitleTextColor(type: TAlertType): string {
  return `text-${colorMap[type]}-800`;
}

function renderBodyTextColor(type: TAlertType): string {
  return `text-${colorMap[type]}-700`;
}
</script>
