import { ApolloProvider } from "@vue/apollo-option";

import { SalesforceApi } from "../api/salesforce/api";
import {
  IntegrationFieldTypeEnum,
  IntegrationServiceEnum,
  TIntegrationObjectMetadata,
  TIntegrationObjectName,
  TIntegrationsObjectsMetadata,
} from "../types/integrations";

export async function fetchObjectsMetadata(
  apolloProvider: ApolloProvider<unknown>,
  service: IntegrationServiceEnum,
  objectNames: string[],
  options?: { refresh?: boolean }
): Promise<TIntegrationObjectMetadata[]> {
  const refresh = options?.refresh ?? false;
  let objectsMetadata;
  if (service === IntegrationServiceEnum.SALESFORCE) {
    const localObjectNames = objectNames.filter((name) => defaultObjects[service]?.[name]);
    const remoteObjectNames = objectNames.filter((name) => !defaultObjects[service]?.[name]);
    const localObjects = getLocalObjectsMetadata(service, localObjectNames);
    const remoteObjects = await fetchSalesforceObjectsMetadata(apolloProvider, remoteObjectNames, {
      refresh,
    });
    objectsMetadata = localObjects.concat(remoteObjects);
  } else {
    objectsMetadata = getLocalObjectsMetadata(service, objectNames);
  }
  return Promise.resolve(objectsMetadata);
}

function getLocalObjectsMetadata(service: IntegrationServiceEnum, objectNames: string[]): TIntegrationObjectMetadata[] {
  return objectNames.map((objectName) => defaultObjects[service]?.[objectName]);
}

async function fetchSalesforceObjectsMetadata(
  apolloProvider: ApolloProvider<unknown>,
  objectNames: TIntegrationObjectName[],
  options?: { refresh?: boolean }
): Promise<TIntegrationObjectMetadata[]> {
  if (!apolloProvider) {
    console.error("fetchSalesforceObjectsMetadata: apolloProvider is not initialized");
    throw new Error(`fetchSalesforceObjectsMetadata: apolloProvider is not initialized`);
  }
  const salesforceObjectsMetadata = new SalesforceApi(apolloProvider);

  const refresh = options?.refresh ?? false;
  if (!objectNames.length) {
    return Promise.resolve([]);
  }

  const result = await salesforceObjectsMetadata.getSalesForceMetadataObjects(objectNames, refresh);

  let objectMetadatas = result ?? [];
  return objectMetadatas.map((objectMetadata) => ({
    ...objectMetadata,
    fields: [
      ...objectMetadata.fields,
      {
        // This isn't an actual Salesforce field, but will be made available by OneGraph on Salesforce objects
        caseSensitive: false,
        custom: false,
        defaultValue: null,
        functionality: {
          createable: false,
          updateable: false,
        },
        label: "Object URLs",
        maxLength: 0,
        name: "sobjectMetadata",
        nameField: false,
        picklistValues: [],
        readonly: true,
        referenceTo: ["sobjectMetadata" as TIntegrationObjectName],
        relationshipName: "sobjectMetadata",
        type: IntegrationFieldTypeEnum.reference,
      },
    ],
  }));
}

// TODO: Serve everything all this from the backend
const defaultObjects: Partial<TIntegrationsObjectsMetadata> = {
  GONG: {
    Call: {
      fields: [
        { label: "URL", name: "url", type: IntegrationFieldTypeEnum.url },
        { label: "Meeting URL", name: "meetingUrl", type: IntegrationFieldTypeEnum.url },
        {
          label: "Direction",
          name: "direction",
          picklistValues: [
            { label: "Conference", value: "CONFERENCE" },
            { label: "Inbound", value: "INBOUND" },
            { label: "Outbound", value: "OUTBOUND" },
            { label: "Unknown", value: "UNKNOWN" },
          ],
          type: IntegrationFieldTypeEnum.picklist,
        },
        { label: "Duration", name: "duration", type: IntegrationFieldTypeEnum.int },
        { label: "ID", name: "id", type: IntegrationFieldTypeEnum.id },
        { label: "Account ID", name: "accountId", type: IntegrationFieldTypeEnum.id },
        { label: "Opportunity ID", name: "opportunityId", type: IntegrationFieldTypeEnum.id },
        { label: "Parties", name: "parties", type: IntegrationFieldTypeEnum.unsupported },
        {
          label: "Content",
          name: "content",
          referenceTo: ["Content"],
          relationshipName: "content",
          type: IntegrationFieldTypeEnum.reference,
        },
        { label: "System", name: "system", type: IntegrationFieldTypeEnum.string },
        { label: "Title", name: "title", type: IntegrationFieldTypeEnum.string },
        { array: true, label: "Teammates", name: "teammates", type: IntegrationFieldTypeEnum.email },
        { array: true, label: "External Attendees", name: "externalAttendees", type: IntegrationFieldTypeEnum.email },
        {
          label: "Primary User ID",
          name: "primaryUserId",
          referenceTo: ["User"],
          relationshipName: "primaryUser",
          type: IntegrationFieldTypeEnum.reference,
        },
      ],
      label: "Call",
      labelPlural: "Calls",
      name: "Call",
    },
    Content: {
      fields: [
        {
          array: true,
          label: "Topics",
          name: "topics",
          referenceTo: ["Topic"],
          relationshipName: "Topics",
          type: IntegrationFieldTypeEnum.reference,
        },
        {
          array: true,
          label: "Structure",
          name: "structure",
          referenceTo: ["Structure"],
          relationshipName: "Structure",
          type: IntegrationFieldTypeEnum.reference,
        },
      ],
      label: "Content",
      labelPlural: "Contents",
      name: "Content",
    },
    Structure: {
      fields: [
        { label: "Name", name: "name", type: IntegrationFieldTypeEnum.string },
        { label: "Duration", name: "duration", type: IntegrationFieldTypeEnum.int },
      ],
      label: "Structure",
      labelPlural: "Structures",
      name: "Structure",
    },
    Topic: {
      fields: [
        { label: "Name", name: "name", type: IntegrationFieldTypeEnum.string },
        { label: "Duration", name: "duration", type: IntegrationFieldTypeEnum.int },
      ],
      label: "Topic",
      labelPlural: "Topics",
      name: "Topic",
    },
    User: {
      fields: [
        { label: "ID", name: "id" },
        { label: "Email Address", name: "emailAddress" },
        { label: "Name", name: "name" },
      ],
      label: "User",
      labelPlural: "Users",
      name: "User",
    },
  },
  GOOGLE_CALENDAR: {
    Attendee: {
      fields: [
        { label: "Display Name", name: "displayName" },
        { label: "Email", name: "email" },
      ],
      label: "Attendee",
      labelPlural: "Attendees",
      name: "Attendee",
    },
    Event: {
      fields: [
        { label: "ID", name: "id" },
        { label: "HTML Link", name: "htmlLink" },
        {
          label: "Creator",
          name: "creator",
          referenceTo: ["Attendee"],
          relationshipName: "Creator",
          type: IntegrationFieldTypeEnum.reference,
        },
        { label: "Text", name: "text" },
        {
          label: "End Time",
          name: "end",
          referenceTo: ["Time"],
          relationshipName: "end",
          type: IntegrationFieldTypeEnum.reference,
        },
        {
          array: true,
          label: "Attendees",
          name: "Attendees",
          referenceTo: ["Attendee"],
          relationshipName: "Attendees",
          type: IntegrationFieldTypeEnum.reference,
        },
        {
          label: "Start Time",
          name: "start",
          referenceTo: ["Time"],
          relationshipName: "start",
          type: IntegrationFieldTypeEnum.reference,
        },
      ],
      label: "Event",
      labelPlural: "Events",
      name: "Event",
    },
    ExpandedEventInfo: {
      fields: [
        { label: "Attendees With LinkedIn", name: "attendeesWithLinkedIn" },
        { label: "Team User Profiles", name: "teamUserProfiles" },
      ],
      label: "Expanded Event Info",
      labelPlural: "ExpandedEventInfos",
      name: "ExpandedEventInfo",
    },
    Time: {
      fields: [
        { label: "Date Time", name: "dateTime" },
        { label: "Timestamp", name: "timestamp" },
      ],
      label: "Time",
      labelPlural: "Times",
      name: "Time",
    },
  },
  MOMENTUM: {
    Action: {
      fields: [
        {
          label: "Creator ID",
          name: "creatorId",
          referenceTo: ["User"],
          relationshipName: "Creator",
          type: IntegrationFieldTypeEnum.reference,
        },
      ],
      label: "Action",
      labelPlural: "Actions",
      name: "Action",
    },
    Activity: {
      fields: [
        { label: "Created At", name: "createdAt" },
        {
          label: "Created By",
          name: "creator",
          referenceTo: ["User"],
          relationshipName: "creator",
          type: IntegrationFieldTypeEnum.reference,
        },
        { label: "Type", name: "type" },
      ],
      label: "Activity",
      labelPlural: "Activities",
      name: "Activity",
    },
    Assist: {
      fields: [
        { label: "Name", name: "Name" },
        {
          label: "Creator ID",
          name: "creatorId",
          referenceTo: ["User"],
          relationshipName: "Creator",
          type: IntegrationFieldTypeEnum.reference,
        },
      ],
      label: "Assist",
      labelPlural: "Assists",
      name: "Assist",
    },
    AssistChannel: {
      fields: [
        { label: "Channel ID", name: "channelId" },
        { label: "Header Message Timestamp", name: "headerMessageTs" },
        { label: "Source Type", name: "sourceType" },
        { label: "Source Value", name: "sourceValue" },
      ],
      label: "Assist Channel",
      labelPlural: "Assist Channels",
      name: "AssistChannel",
    },
    Call: {
      fields: [
        // {
        //   name: "id",
        //   label: "ID",
        //   type: IntegrationFieldTypeEnum.id,
        // },
        {
          label: "Source",
          name: "sourceType",
          picklistValues: [
            { label: "Gong", value: "GONG" },
            { label: "Zoom", value: "ZOOM" },
            { label: "Salesloft", value: "SALESLOFT" },
          ],
          type: IntegrationFieldTypeEnum.picklist,
        },
        {
          array: true,
          label: "Suggested Actions",
          name: "suggestedActions",
          type: IntegrationFieldTypeEnum.string,
        },
        {
          label: "Suggested Summary",
          name: "suggestedSummary",
          type: IntegrationFieldTypeEnum.string,
        },
        // {
        //   name: "url",
        //   label: "URL",
        //   type: IntegrationFieldTypeEnum.url,
        // },
        // {
        //   name: "createdAt",
        //   label: "Created At",
        //   type: IntegrationFieldTypeEnum.datetime,
        // },
      ],
      label: "Call",
      labelPlural: "Calls",
      name: "Call",
    },
    ChannelPostedMessage: {
      fields: [
        { label: "Slack Channel ID", name: "slackChannelId" },
        { label: "Channel Type", name: "channelType" },
        { label: "Timestamp", name: "ts" },
        { label: "Slack User ID", name: "slackUserId" },
        { label: "Message", name: "message" },
      ],
      label: "Channel Posted Message",
      labelPlural: "Channel Posted Messages",
      name: "ChannelPostedMessage",
    },
    CronScheduler: {
      fields: [{ label: "Cron", name: "cron" }],
      label: "Cron Scheduler",
      labelPlural: "Custom Schedulers",
      name: "CronScheduler",
    },
    // Hosting under MOMENTUM, since CustomObject isn't a real Salesforce object
    CustomObject: {
      fields: [
        { label: "ID", name: "id" },
        {
          label: "Salesforce Object Metadata",
          name: "sobjectMetadata",
          referenceTo: ["sobjectMetadata"],
          relationshipName: "sobjectMetadata",
          type: IntegrationFieldTypeEnum.reference,
        },
      ],
      label: "Custom Object",
      labelPlural: "Custom Objects",
      name: "CustomObject",
    },
    Dealroom: {
      fields: [
        { label: "Available", name: "available" },
        { label: "Account ID", name: "accountId" },
        { label: "Deal Room ID", name: "id" },
        { label: "Opportunity ID", name: "opportunityId" },
        { label: "Slack Channel ID", name: "slackChannelId" },
        { label: "Stage Name", name: "stageName" },
        {
          label: "Owner ID",
          name: "ownerId",
          referenceTo: ["User"],
          relationshipName: "Owner",
          type: IntegrationFieldTypeEnum.reference,
        },
      ],
      label: "Deal Room",
      labelPlural: "Deal Rooms",
      name: "Dealroom",
    },
    FiscalPeriods: {
      fields: [
        {
          array: true,
          label: "Quarters",
          name: "Quarters",
          referenceTo: ["Quarters"],
          relationshipName: "Quarters",
          type: IntegrationFieldTypeEnum.reference,
        },
        {
          array: true,
          label: "Months",
          name: "Months",
          referenceTo: ["Months"],
          relationshipName: "Months",
          type: IntegrationFieldTypeEnum.reference,
        },
      ],
      label: "FiscalPriods",
      labelPlural: "FiscalPeriods",
      name: "FiscalPeriods",
    },
    Meeting: {
      fields: [
        { label: "Id", name: "id", type: IntegrationFieldTypeEnum.id },
        {
          label: "Call",
          name: "id",
          referenceTo: ["Call"],
          relationshipName: "call",
          type: IntegrationFieldTypeEnum.reference,
        },
        { label: "End Time", name: "endTime", type: IntegrationFieldTypeEnum.datetime },
        {
          label: "Host User ID",
          limitSelectableToOnlyObjectType: true,
          name: "hostUserId",
          referenceTo: ["User"],
          relationshipName: "hostUser",
          type: IntegrationFieldTypeEnum.reference,
        },
        {
          label: "Is External?",
          name: "isExternal",
          type: IntegrationFieldTypeEnum.boolean,
        },
        {
          label: "Momentum Owner ID",
          name: "momentumOwnerId",
          referenceTo: ["User"],
          relationshipName: "momentumOwner",
          type: IntegrationFieldTypeEnum.reference,
        },
        //same type of error as Call ID
        // {
        //   name: "salesforceAccountId",
        //   label: "Salesforce Account ID",
        //   type: IntegrationFieldTypeEnum.reference,
        //   referenceTo: ["SalesforceAccount"],
        //   relationshipName: "salesforceAccount",
        // },
        //same type of error as Call ID
        // {
        //   name: "salesforceLeadId",
        //   label: "Salesforce Lead ID",
        //   type: IntegrationFieldTypeEnum.reference,
        //   referenceTo: ["SalesforceLead"],
        //   relationshipName: "salesforceOpportunity",
        // },

        //same type of error as Call ID
        // {
        //   name: "salesforceOpportunityId",
        //   label: "Salesforce Opportunity ID",
        //   type: IntegrationFieldTypeEnum.reference,
        //   referenceTo: ["SalesforceOpportunity"],
        //   relationshipName: "salesforceOpportunity",
        // },
        //same type of error as Call ID
        // {
        //   name: "salesforceOwnerId",
        //   label: "Salesforce Owner ID",
        //   type: IntegrationFieldTypeEnum.reference,
        //   referenceTo: ["SalesforceUser"],
        //   relationshipName: "salesforceOwner",
        // },
        { label: "Slack Note Channel", name: "slackNoteChannel", type: IntegrationFieldTypeEnum.string },
        { label: "Slack Note Timestamp", name: "slackNoteTs", type: IntegrationFieldTypeEnum.string },
        { label: "Slack Note URL", name: "slackNoteUrl", type: IntegrationFieldTypeEnum.url },
        { label: "Start Time", name: "startTime", type: IntegrationFieldTypeEnum.datetime },
        { label: "Title", name: "title", type: IntegrationFieldTypeEnum.string },
        { label: "URL", name: "url", type: IntegrationFieldTypeEnum.url },
      ],
      label: "Meeting",
      labelPlural: "Meetings",
      name: "Meeting",
    },
    MeetingNote: {
      fields: [
        { label: "Meeting Note ID", name: "id" },
        { label: "Meeting ID", name: "meetingId" },
        { label: "Created At", name: "createdAt" },
        { label: "Completed At", name: "completedAt" },
        { label: "Title", name: "title" },
        { label: "Content", name: "content" },
        { label: "Source Type", name: "sourceType" },
        { label: "Source Value", name: "sourceValue" },
        { label: "Salesforce Note ID", name: "salesforceNoteId" },
        { label: "Last Synced At", name: "lastSyncedAt" },
      ],
      label: "Meeting Note",
      labelPlural: "Meeting Notes",
      name: "MeetingNote",
    },
    Months: {
      fields: [
        {
          label: "current",
          name: "current",
          referenceTo: ["Period"],
          relationshipName: "Period",
          type: IntegrationFieldTypeEnum.reference,
        },
        {
          array: true,
          label: "list",
          name: "list",
          referenceTo: ["Period"],
          relationshipName: "Period",
          type: IntegrationFieldTypeEnum.reference,
        },
        {
          label: "next",
          name: "next",
          referenceTo: ["Period"],
          relationshipName: "Period",
          type: IntegrationFieldTypeEnum.reference,
        },
        {
          label: "previous",
          name: "previous",
          referenceTo: ["Period"],
          relationshipName: "Period",
          type: IntegrationFieldTypeEnum.reference,
        },
      ],
      label: "Months",
      labelPlural: "Months",
      name: "Months",
    },
    NoteActionItem: {
      fields: [
        { label: "Note Action Item ID", name: "id" },
        { label: "Meeting ID", name: "meetingId" },
        { label: "Created At", name: "createdAt" },
        { label: "Completed At", name: "completedAt" },
        { label: "Title", name: "title" },
        { label: "Description", name: "description" },
        { label: "Is Completed", name: "isCompleted" },
        {
          label: "Assigned User",
          name: "assignedUser",
          referenceTo: ["User"],
          relationshipName: "assignedUser",
          type: IntegrationFieldTypeEnum.reference,
        },
      ],
      label: "Note Action Item",
      labelPlural: "Note Action Items",
      name: "NoteActionItem",
    },
    Organization: {
      fields: [
        { label: "Organization ID", name: "id" },
        { label: "Name", name: "name" },
        {
          label: "Slack Workspace ID",
          name: "slackWorkspaceId",
          referenceTo: ["SlackWorkspace"],
          relationshipName: "slackWorkspace",
          type: IntegrationFieldTypeEnum.reference,
        },
      ],
      label: "Organization",
      labelPlural: "Organizations",
      name: "Organization",
    },
    Period: {
      fields: [
        {
          label: "endDate",
          name: "endDate",
          type: IntegrationFieldTypeEnum.datetime,
        },
        {
          label: "number",
          name: "number",
          type: IntegrationFieldTypeEnum.int,
        },
        {
          label: "startDate",
          name: "startDate",
          type: IntegrationFieldTypeEnum.datetime,
        },
      ],
      label: "Periods",
      labelPlural: "Periods",
      name: "Period",
    },
    Quarters: {
      fields: [
        {
          label: "current",
          name: "current",
          referenceTo: ["Period"],
          relationshipName: "Period",
          type: IntegrationFieldTypeEnum.reference,
        },
        {
          array: true,
          label: "list",
          name: "list",
          referenceTo: ["Period"],
          relationshipName: "Period",
          type: IntegrationFieldTypeEnum.reference,
        },
        {
          label: "next",
          name: "next",
          referenceTo: ["Period"],
          relationshipName: "Period",
          type: IntegrationFieldTypeEnum.reference,
        },
        {
          label: "previous",
          name: "previous",
          referenceTo: ["Period"],
          relationshipName: "Period",
          type: IntegrationFieldTypeEnum.reference,
        },
      ],
      label: "Quarters",
      labelPlural: "Quarters",
      name: "Quarters",
    },
    Reaction: {
      fields: [
        {
          label: "Created",
          name: "creator",
          referenceTo: ["User"],
          relationshipName: "creator",
          type: IntegrationFieldTypeEnum.reference,
        },
        { label: "Message", name: "message" },
        { label: "Files", name: "files" },
        { label: "Slack Channel ID", name: "slackChannelId" },
        { label: "Emoji", name: "emoji" },
        { label: "Message Author", name: "messageAuthor" },
        { label: "Message Timestamp", name: "messageTs" },
        { label: "Metadata", name: "metadata" },
      ],
      label: "Reaction",
      labelPlural: "Reactions",
      name: "Reaction",
    },
    // Since we can't support cross-service objects, duplicate Salesforce objects required by MOMENTUM here
    SalesforceAccount: {
      fields: [
        { label: "ID", name: "Id", type: IntegrationFieldTypeEnum.string },
        {
          label: "Name",
          name: "Name",
          type: IntegrationFieldTypeEnum.string,
        },
        {
          label: "Owner ID",
          name: "OwnerId",
          referenceTo: ["SalesforceUser"],
          relationshipName: "Owner",
          type: IntegrationFieldTypeEnum.reference,
        },
      ],
      label: "Salesforce Account",
      labelPlural: "Salesforce Accounts",
      name: "SalesforceAccount",
    },
    SalesforceLead: {
      fields: [
        { label: "ID", name: "Id", type: IntegrationFieldTypeEnum.id },
        {
          label: "Name",
          name: "Name",
          type: IntegrationFieldTypeEnum.string,
        },
        {
          label: "Email",
          name: "Email",
          type: IntegrationFieldTypeEnum.email,
        },
        {
          label: "Owner ID",
          name: "OwnerId",
          referenceTo: ["SalesforceUser"],
          relationshipName: "Owner",
          type: IntegrationFieldTypeEnum.reference,
        },
      ],
      label: "Salesforce Lead",
      labelPlural: "Salesforce Leads",
      name: "SalesforceLead",
    },
    SalesforceOpportunity: {
      fields: [
        { label: "ID", name: "Id", type: IntegrationFieldTypeEnum.id },
        {
          label: "Name",
          name: "Name",
          type: IntegrationFieldTypeEnum.string,
        },
        {
          label: "Account ID",
          name: "AccountId",
          referenceTo: ["SalesforceAccount"],
          relationshipName: "Account",
          type: IntegrationFieldTypeEnum.reference,
        },
        {
          label: "Owner ID",
          name: "OwnerId",
          referenceTo: ["SalesforceUser"],
          relationshipName: "Owner",
          type: IntegrationFieldTypeEnum.reference,
        },
      ],
      label: "Salesforce Opportunity",
      labelPlural: "Salesforce Opportunities",
      name: "SalesforceOpportunity",
    },
    SalesforceUser: {
      fields: [
        { label: "ID", name: "Id", type: IntegrationFieldTypeEnum.id },
        {
          label: "Name",
          name: "Name",
          type: IntegrationFieldTypeEnum.string,
        },
        {
          label: "Email",
          name: "Email",
          type: IntegrationFieldTypeEnum.email,
        },
      ],
      label: "Salesforce User",
      labelPlural: "Salesforce Users",
      name: "SalesforceUser",
    },
    SlackAction: {
      fields: [
        { label: "Type", name: "type" },
        { label: "Trigger ID", name: "triggerId" },
        { label: "Payload", name: "payload" },
      ],
      label: "Slack Action",
      labelPlural: "Slack Actions",
      name: "SlackAction",
    },
    SlackFile: {
      fields: [
        { label: "Created", name: "created" },
        { label: "File Type", name: "fileType" },
        { label: "Slack File ID", name: "id" },
        { label: "Link", name: "link" },
        { label: "Name", name: "name" },
      ],
      label: "Slack File",
      labelPlural: "Slack Files",
      name: "SlackFile",
    },
    SlackFileUpload: {
      fields: [
        {
          label: "Creator",
          name: "creator",
          referenceTo: ["User"],
          relationshipName: "creator",
          type: IntegrationFieldTypeEnum.reference,
        },
        { label: "Message Timestamp", name: "messageTs" },
        {
          label: "File",
          name: "file",
          referenceTo: ["SlackFile"],
          relationshipName: "file",
          type: IntegrationFieldTypeEnum.reference,
        },
        { label: "Slack Channel ID", name: "slackChannelId" },
      ],
      label: "Slack File Upload",
      labelPlural: "Slack File Uploads",
      name: "SlackFileUpload",
    },
    SlackReceiver: {
      fields: [
        { label: "Slack Receiver Reference", name: "ref" },
        { label: "Slack Channel ID", name: "slackChannelId" },
        { label: "Slack User ID", name: "slackUserId" },
        { label: "Timestamp", name: "ts" },
        { label: "Type", name: "type" },
      ],
      label: "Slack Receiver",
      labelPlural: "Slack Receivers",
      name: "SlackReceiver",
    },
    SlackWorkspace: {
      fields: [{ binding: "teamID", label: "Team ID", name: "teamID" }],
      label: "Slack Workspace",
      labelPlural: "Slack Workspaces",
      name: "SlackWorkspace",
    },
    // Duplicating under MOMENTUM, since CustomObject isn't a real Salesforce object and requires it
    sobjectMetadata: {
      fields: [
        { label: "UI Detail URL", name: "uiDetailUrl" },
        { label: "UI Edit URL", name: "uiEditUrl" },
      ],
      label: "Salesforce Object URLs",
      labelPlural: "Salesforce Objects URLs",
      name: "sobjectMetadata",
    },
    User: {
      fields: [
        { label: "Name", name: "name" },
        { label: "Email Address", name: "email", type: IntegrationFieldTypeEnum.email },
        { label: "Image URL", name: "imageUrl" },
        { label: "Slack User ID", name: "slackUserId", type: IntegrationFieldTypeEnum.id },
        { label: "Salesforce User ID", name: "salesforceUserId" },
      ],
      label: "User",
      labelPlural: "Users",
      name: "User",
    },
  },
  SALESFORCE: {
    sobjectMetadata: {
      fields: [
        {
          array: false,
          caseSensitive: false,
          custom: false,
          defaultValue: null,
          functionality: {
            createable: false,
            updateable: false,
          },
          label: "Detail URL",
          maxLength: 0,
          name: "uiDetailUrl",
          nameField: false,
          picklistValues: [],
          readonly: true,
          referenceTo: [],
          relationshipName: null,
          type: IntegrationFieldTypeEnum.url,
        },
        {
          array: false,
          caseSensitive: false,
          custom: false,
          defaultValue: null,
          functionality: {
            createable: false,
            updateable: false,
          },
          label: "Edit URL",
          maxLength: 0,
          name: "uiEditUrl",
          nameField: false,
          picklistValues: [],
          readonly: true,
          referenceTo: [],
          relationshipName: null,
          type: IntegrationFieldTypeEnum.url,
        },
      ],
      label: "Salesforce Object Metadata",
      labelPlural: "Salesforce Objects Metadata",
      name: "sobjectMetadata",
    },
  },
  STRIPE: {
    Address: {
      fields: [
        { label: "City", name: "city" },
        { label: "Country", name: "country" },
        { label: "Line 1", name: "line1" },
        { label: "Line 2", name: "line2" },
        { label: "Postal Code", name: "postalCode" },
        { label: "State", name: "state" },
      ],
      label: "Address",
      labelPlural: "Addresses",
      name: "Address",
    },
    Customer: {
      fields: [
        {
          label: "Address",
          name: "address",
          referenceTo: ["Address"],
          relationshipName: "address",
          type: IntegrationFieldTypeEnum.reference,
        },
        { label: "Description", name: "description" },
        { label: "Email", name: "email" },
        { label: "Customer ID", name: "id" },
        { label: "Name", name: "name" },
      ],
      label: "Customer",
      labelPlural: "Customers",
      name: "Customer",
    },
    Plan: {
      fields: [
        { label: "Amount", name: "amount" },
        { label: "Currency", name: "currency" },
        { label: "Plan ID", name: "id" },
        { label: "Interval", name: "interval" },
        { label: "Nickname", name: "nickname" },
      ],
      label: "Plan",
      labelPlural: "Plans",
      name: "Plan",
    },
    PreviousSubscription: {
      fields: [
        { label: "Current Period End", name: "currentPeriodEnd" },
        { label: "Current Period Start", name: "currentPeriodStart" },
        { label: "Previous Subscription ID", name: "id" },
        { label: "Latest Invoice", name: "latestInvoice" },
        {
          label: "Plan",
          name: "plan",
          referenceTo: ["Plan"],
          relationshipName: "plan",
          type: IntegrationFieldTypeEnum.reference,
        },
      ],
      label: "PreviousSubscription",
      labelPlural: "PreviousSubscriptions",
      name: "PreviousSubscription",
    },
    Subscription: {
      fields: [
        { label: "Created", name: "created" },
        { label: "Collection Method", name: "collectionMethod" },
        { label: "Current Period End", name: "currentPeriodEnd" },
        { label: "Current Period Start", name: "currentPeriodStart" },
        { label: "Subscription ID", name: "id" },
        {
          label: "Plan",
          name: "plan",
          referenceTo: ["Plan"],
          relationshipName: "plan",
          type: IntegrationFieldTypeEnum.reference,
        },
        { label: "Quantity", name: "quantity" },
        { label: "Status", name: "status" },
        { label: "Start Date", name: "startDate" },
      ],
      label: "Subscription",
      labelPlural: "Subscriptions",
      name: "Subscription",
    },
  },
};
