import { DocumentNode } from "graphql/index";
import gql from "graphql-tag";

import {
  IntegrationFieldTypeEnum,
  TIntegrationFieldMetadata,
  TIntegrationObjectMetadata,
} from "../../types/integrations";
import { TSalesforceReportName } from "../../types/momentum";
import { TOpportunity } from "../../types/salesforce";
import { BaseApi } from "../base";
import {
  TSalesforceEntityRecord,
  TSalesforceFieldValues,
  TSalesforceObject,
  TSalesforceObjectFields,
  TSalesforceObjectNode,
  TSalesforceObjectNodeFilter,
  TSalesforceSearchFilter,
} from "./types";
import { addFilters } from "./utils";

// TODO: transition this to TSalesforceObjectNode
export type TObjectNode = { description: string; id: string; name: string; sobjectMetadata: { uiDetailUrl: string } };
export class SalesforceApi extends BaseApi {
  async searchSalesforceObjects(options: {
    additionalFilters?: TSalesforceObjectNodeFilter[];
    first: number;
    objectName: string;
    searchTerm: string;
  }): Promise<TSalesforceObjectNode[]> {
    type TVariables = { first: number; objectName: string; searchTerm: string };
    type TData = { momentum: { salesforce: { objects: { nodes: TSalesforceObjectNode[] } } } };
    const query: DocumentNode = gql`
        query SearchSalesforceObjectsNodes($objectName: String!, $first: Int!, $searchTerm: JSON!) {
          momentum {
            salesforce {
              objects(
                objectName: $objectName
                searchOptions: {
                  first: $first
                  fields: ["Id", "Name"]
                  filters: [{ field: "name", operator: "like", value: $searchTerm }${addFilters(
                    options.additionalFilters
                  )}]
                }
              ) {
                nodes
                totalCount
              }
            }
          }
        }
      `;

    try {
      const result: TData = await this.query<TData, TVariables>(query, options);
      return result.momentum.salesforce.objects.nodes;
    } catch (error) {
      this.handleError(error);
    }
  }

  async fetchSalesforceObjects(limit: number, search: string): Promise<TSalesforceObject[]> {
    type TVariables = { limit: number; search: string };
    type TData = { salesforce_object: TSalesforceObject[] };

    const query: DocumentNode = gql`
      query FetchSalesforceObjects(
        $limit: Int
        $offset: Int
        $includeNullKeyPrefix: Boolean = false
        $search: String = "Met"
      ) {
        salesforce_object(
          limit: $limit
          offset: $offset
          where: {
            _or: [{ keyPrefix: { _is_null: false } }, { keyPrefix: { _is_null: $includeNullKeyPrefix } }]
            label: { _ilike: $search }
          }
          order_by: { label: asc }
        ) {
          label
          name
          keyPrefix
        }
      }
    `;

    try {
      const result: TData = await this.query<TData, TVariables>(query, { limit, search: `%${search}%` });
      return result.salesforce_object;
    } catch (error) {
      this.handleError(error);
    }
  }

  async searchSalesforceReports(search: string, limit: number): Promise<TSalesforceReportName[]> {
    type TVariables = { limit: number; search: string };
    type TData = { momentum: { salesforce: { reportNames: TSalesforceReportName[] } } };
    const options: TVariables = { limit, search: `%${search}%` };
    const query: DocumentNode = gql`
      query SearchSalesforceReports($limit: Int!, $search: JSON!) {
        momentum {
          salesforce {
            reportNames(
              searchOptions: {
                first: $limit
                fields: "Name"
                filters: [{ field: "Name", operator: "like", value: $search }]
              }
            ) {
              Id
              Name
            }
          }
        }
      }
    `;

    try {
      const result: TData = await this.query<TData, TVariables>(query, options);
      return result.momentum.salesforce.reportNames;
    } catch (error) {
      this.handleError(error);
    }
  }

  async fetchSalesforceObjectFields(
    objectNames: string[],
    options?: { refresh: boolean }
  ): Promise<Record<string, TSalesforceObjectFields[]>> {
    type TVariables = { objectNames: string[]; refresh: boolean };
    type TData = { momentum: { salesforce: { objectMetadata: TIntegrationObjectMetadata[] } } };
    const query: DocumentNode = gql`
      query FetchSalesforceObjectFields($objectNames: [String!]!, $refresh: Boolean!) {
        momentum {
          salesforce {
            objectMetadata(objectNames: $objectNames, refresh: $refresh) {
              name
              custom
              functionality
              label
              labelPlural
              fields {
                caseSensitive
                custom
                defaultValue
                functionality
                label
                maxLength
                name
                nameField
                objectName
                picklistValues
                readonly
                referenceTo
                relationshipName
                type
                omitFromQuery
              }
            }
          }
        }
      }
    `;

    try {
      const result: TData = await this.query<TData, TVariables>(query, {
        objectNames,
        refresh: options?.refresh ?? false,
      });
      const objectMetadata = result.momentum.salesforce.objectMetadata;
      const sfFields: Record<string, TSalesforceObjectFields[]> = {};
      objectNames.forEach((object) => {
        const objectData = objectMetadata.filter((item) => item.name === object);
        const fieldArray = objectData?.[0]?.fields?.filter((item) => item.type !== IntegrationFieldTypeEnum.reference);
        if (!fieldArray) {
          return;
        }
        fieldArray.sort((a, b) => a.label.localeCompare(b.label));
        sfFields[object] = fieldArray.map((item) => {
          return {
            label: item.label,
            type: item.type,
            value: item.name,
          };
        });
      });
      return sfFields;
    } catch (error) {
      this.handleError(error);
    }
  }

  async getObjectRequiredFields(objectName: string): Promise<TIntegrationFieldMetadata[]> {
    type TData = { momentum: { salesforce: { objectMetadata: TIntegrationObjectMetadata[] } } };
    const query: DocumentNode = gql`
      query GetSalesforceObjectsMetadata($objectNames: [String!]!, $refresh: Boolean!) {
        momentum {
          salesforce {
            objectMetadata(objectNames: $objectNames, refresh: $refresh) {
              name
              custom
              functionality
              label
              labelPlural
              fields {
                caseSensitive
                custom
                defaultValue
                functionality
                label
                maxLength
                name
                nameField
                objectName
                picklistValues
                readonly
                referenceTo
                relationshipName
                type
                omitFromQuery
              }
            }
          }
        }
      }
    `;

    try {
      const result: TData = await this.query<TData, { objectNames: string[]; refresh: boolean }>(query, {
        objectNames: [objectName],
        refresh: true,
      });
      return result.momentum.salesforce.objectMetadata[0].fields.filter((field) => {
        return (
          field.defaultValue === null && field.functionality?.nillable === false && field.functionality?.updateable
        );
      });
    } catch (error) {
      this.handleError(error);
    }
  }

  async getSalesforceReportColumns(reportId: string): Promise<string[]> {
    type TVariables = { reportId: string };
    type TData = { momentum: { salesforce: { reportColumns: { columns: string[] } } } };
    const query: DocumentNode = gql`
      query GetSalesforceReportColumns($reportId: String!) {
        momentum {
          salesforce {
            reportColumns(reportId: $reportId) {
              columns
            }
          }
        }
      }
    `;
    try {
      const result: TData = await this.query<TData, TVariables>(query, { reportId });
      return result.momentum.salesforce.reportColumns.columns;
    } catch (error) {
      this.handleError(error);
    }
  }

  async getSalesforceUserRoles(): Promise<{ label: string; value: number }[]> {
    type TData = { salesforce_user_role: { id: number; name: string }[] };
    const query: DocumentNode = gql`
      query GetSalesforceUserRoles {
        salesforce_user_role {
          name
          id
        }
      }
    `;
    try {
      const result: TData = await this.query<TData, null>(query);
      return result.salesforce_user_role
        .map((role) => ({ label: role.name, value: role.id }))
        .sort((a, b) => a.label.localeCompare(b.label));
    } catch (error) {
      this.handleError(error);
    }
  }

  async getSalesForceOpportunityFields() {
    type TData = {
      organization: {
        salesForceOpportunityFields: {
          fieldMetadata: string;
          id: string;
          label: string;
          name: string;
          type: string;
          updatedAt: string;
        }[];
      };
    };

    const query: DocumentNode = gql`
      query OpportunityFields {
        organization {
          salesForceOpportunityFields: sales_force_opportunity_fields(order_by: { label: asc }) {
            id
            fieldMetadata
            name
            label
            type
            updatedAt
          }
        }
      }
    `;

    try {
      const response = await this.query<TData, void>(query);
      return response.organization[0].salesForceOpportunityFields;
    } catch (error) {
      this.handleError(error);
    }
  }

  async getSalesForceMetadataObjects(objectNames: string[], refresh: boolean): Promise<TIntegrationObjectMetadata[]> {
    type TVariables = { objectNames: string[]; refresh: boolean };
    type TData = { momentum: { salesforce: { objectMetadata: TIntegrationObjectMetadata[] } } };

    const query: DocumentNode = gql`
      query SalesforceObjectsMetadata($objectNames: [String!]!, $refresh: Boolean!) {
        momentum {
          salesforce {
            objectMetadata(objectNames: $objectNames, refresh: $refresh) {
              name
              custom
              functionality
              label
              labelPlural
              fields {
                caseSensitive
                custom
                defaultValue
                functionality
                label
                maxLength
                name
                nameField
                objectName
                picklistValues
                readonly
                referenceTo
                relationshipName
                type
                omitFromQuery
              }
            }
          }
        }
      }
    `;

    try {
      const result: TData = await this.query<TData, TVariables>(query, { objectNames, refresh });
      return result.momentum.salesforce.objectMetadata;
    } catch (error) {
      this.handleError(error);
    }
  }

  async getSalesforceOpportunity(id: string): Promise<TOpportunity> {
    type TVariables = { id: string };
    type TData = {
      momentum: {
        salesforce: {
          object: TOpportunity;
        };
      };
    };

    const query: DocumentNode = gql`
      query SalesforceOpportunity($id: String!) {
        momentum {
          salesforce {
            object(
              objectName: "Opportunity"
              objectId: $id
              fields: ["Account.Id", "Account.Name", "Amount", "Id", "LastModifiedDate", "Name", "StageName"]
            )
          }
        }
      }
    `;

    try {
      const result: TData = await this.query<TData, TVariables>(query, { id });
      return result.momentum.salesforce.object;
    } catch (error) {
      this.handleError(error);
    }
  }

  async searchSalesforceRecords(
    objectName: string,
    fields: string[],
    filters: TSalesforceSearchFilter[],
    first: number
  ): Promise<TSalesforceEntityRecord[]> {
    type TVariables = {
      fields: string[];
      filters: TSalesforceSearchFilter[];
      first: number;
      objectName: string;
    };
    type TData = {
      momentum: {
        salesforce: {
          objects: {
            nodes: TSalesforceEntityRecord[];
          };
        };
      };
    };

    const query: DocumentNode = gql`
      query SearchSalesforceRecords(
        $objectName: String!
        $filters: [TSalesforceSearchFilter!]!
        $fields: [String!]!
        $first: Int!
      ) {
        momentum {
          salesforce {
            objects(objectName: $objectName, searchOptions: { filters: $filters, fields: $fields, first: $first }) {
              nodes
            }
          }
        }
      }
    `;

    try {
      const result: TData = await this.query<TData, TVariables>(query, { fields, filters, first, objectName });
      return result.momentum.salesforce.objects.nodes;
    } catch (error) {
      this.handleError(error);
    }
  }

  async getSalesforceFieldValues(id: string, fields: string[]): Promise<TSalesforceFieldValues> {
    type TVariables = { fields: string[]; id: string };
    type TData = {
      momentum: {
        salesforce: {
          object: TSalesforceFieldValues;
        };
      };
    };

    const query: DocumentNode = gql`
      query salesforceFieldValues($id: String!, $fields: [String!]!) {
        momentum {
          salesforce {
            object(objectName: "Opportunity", objectId: $id, fieldNames: $fields)
          }
        }
      }
    `;

    try {
      const result: TData = await this.query<TData, TVariables>(query, { fields, id });
      return result.momentum.salesforce.object;
    } catch (error) {
      this.handleError(error);
    }
  }

  async updateSalesforceFieldMetadataOmitFromQuery(objectName: string, fieldNames: string[]): Promise<void> {
    type TVariables = { fieldNames: string[]; objectName: string };
    type TData = { momentum: { salesforce: { updateFieldMetadataOmitFromQuery: void } } };
    const query: DocumentNode = gql`
      mutation UpdateSalesforceFieldMetadataOmitFromQuery($objectName: String!, $fieldNames: [String!]!) {
        momentum {
          salesforce {
            updateFieldMetadataOmitFromQuery(objectName: $objectName, fieldNames: $fieldNames)
          }
        }
      }
    `;

    try {
      const result: TData = await this.mutate<TData, TVariables>(query, { fieldNames, objectName });
      this.postSuccess("Field settings updated.");
      return result.momentum.salesforce.updateFieldMetadataOmitFromQuery;
    } catch (error) {
      this.handleError(error);
    }
  }

  async getSalesforceObjects(options: {
    first?: number;
    objectIds?: string[];
    objectName?: string;
  }): Promise<TObjectNode[]> {
    type TVariables = { first: number; ids: string[]; objectName: string };
    type TData = { momentum: { salesforce: { objects: { nodes: TObjectNode[] } } } };
    const query: DocumentNode = gql`
      query GetSalesforceObjectNamesByIds($ids: JSON!, $objectName: String!, $first: Int! = 100) {
        momentum {
          salesforce {
            objects(
              objectName: $objectName
              searchOptions: {
                first: $first
                filters: [{ field: "Id", operator: "in", value: $ids }]
                fields: ["Id", "Name"]
              }
            ) {
              nodes
              totalCount
            }
          }
        }
      }
    `;

    try {
      const result: TData = await this.query<TData, TVariables>(query, {
        first: options.first || 100,
        ids: options.objectIds || [],
        objectName: options.objectName || "Opportunity",
      });
      return result.momentum.salesforce.objects.nodes;
    } catch (error) {
      this.handleError(error);
    }
  }
}
