<template>
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M11.4833 7.89583L7.31246 12.0667C7.19579 12.1833 7.06454 12.2708 6.91871 12.3292C6.77288 12.3875 6.62704 12.4167 6.48121 12.4167C6.33538 12.4167 6.18954 12.3875 6.04371 12.3292C5.89788 12.2708 5.76663 12.1833 5.64996 12.0667L0.502043 6.91875C0.395098 6.81181 0.312459 6.68785 0.254126 6.54688C0.195793 6.4059 0.166626 6.25764 0.166626 6.10208V1.91667C0.166626 1.59583 0.280862 1.32118 0.509334 1.09271C0.737807 0.864236 1.01246 0.75 1.33329 0.75H5.51871C5.67426 0.75 5.82496 0.781597 5.97079 0.844792C6.11663 0.907986 6.24301 0.993056 6.34996 1.1L11.4833 6.24792C11.6 6.36458 11.685 6.49583 11.7385 6.64167C11.792 6.7875 11.8187 6.93333 11.8187 7.07917C11.8187 7.225 11.792 7.3684 11.7385 7.50938C11.685 7.65035 11.6 7.77917 11.4833 7.89583ZM2.79163 4.25C3.03468 4.25 3.24128 4.16493 3.41142 3.99479C3.58156 3.82465 3.66663 3.61806 3.66663 3.375C3.66663 3.13194 3.58156 2.92535 3.41142 2.75521C3.24128 2.58507 3.03468 2.5 2.79163 2.5C2.54857 2.5 2.34197 2.58507 2.17183 2.75521C2.0017 2.92535 1.91663 3.13194 1.91663 3.375C1.91663 3.61806 2.0017 3.82465 2.17183 3.99479C2.34197 4.16493 2.54857 4.25 2.79163 4.25Z" fill="#0090FF" />
  </svg>
</template>
