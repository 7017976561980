<template>
  <svg
    width="21"
    height="19"
    viewBox="0 0 21 19"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1.5 16C1.1 16 0.75 15.85 0.45 15.55C0.15 15.25 0 14.9 0 14.5V1.5C0 1.1 0.15 0.75 0.45 0.45C0.75 0.15 1.1 0 1.5 0H18.5C18.9 0 19.25 0.15 19.55 0.45C19.85 0.75 20 1.1 20 1.5V9.85C19.5833 9.65 19.1542 9.5 18.7125 9.4C18.2708 9.3 17.8167 9.25 17.35 9.25C15.65 9.25 14.2083 9.84167 13.025 11.025C11.8417 12.2083 11.25 13.65 11.25 15.35V16H1.5ZM10 6.825L2.475 2.1C2.24167 1.96667 2.02083 1.96667 1.8125 2.1C1.60417 2.23333 1.5 2.41667 1.5 2.65C1.5 2.76667 1.52917 2.87083 1.5875 2.9625C1.64583 3.05417 1.71667 3.125 1.8 3.175L9.6 8.075C9.73333 8.15833 9.86667 8.2 10 8.2C10.1333 8.2 10.2667 8.15833 10.4 8.075L18.2 3.175C18.3 3.125 18.375 3.05417 18.425 2.9625C18.475 2.87083 18.5 2.76667 18.5 2.65C18.5 2.4 18.3958 2.2125 18.1875 2.0875C17.9792 1.9625 17.7583 1.96667 17.525 2.1L10 6.825ZM18.125 16H13.5C13.2833 16 13.1042 15.9292 12.9625 15.7875C12.8208 15.6458 12.75 15.4667 12.75 15.25C12.75 15.0333 12.8208 14.8542 12.9625 14.7125C13.1042 14.5708 13.2833 14.5 13.5 14.5H18.125L16.8 13.175C16.65 13.025 16.5792 12.85 16.5875 12.65C16.5958 12.45 16.675 12.275 16.825 12.125C16.975 11.975 17.15 11.9 17.35 11.9C17.55 11.9 17.725 11.975 17.875 12.125L20.475 14.725C20.625 14.875 20.7 15.05 20.7 15.25C20.7 15.45 20.625 15.625 20.475 15.775L17.875 18.375C17.725 18.525 17.55 18.6 17.35 18.6C17.15 18.6 16.975 18.525 16.825 18.375C16.675 18.225 16.6042 18.05 16.6125 17.85C16.6208 17.65 16.6917 17.475 16.825 17.325L18.125 16Z" fill="currentColor" />
  </svg>
</template>
