<template>
  <component :is="state.layout" v-if="state.layout">
    <slot />
  </component>
  <div v-else />
</template>

<script lang="ts">
import { defineComponent, reactive, shallowRef, watch } from "vue";
import { RouteLocationNormalizedLoaded, useRoute } from "vue-router";

import GlobalMomentumSupport from "../console-support/layouts/GlobalMomentumSupport.vue";
import MomentumSupport from "../console-support/layouts/MomentumSupport.vue";
import AdminDashboard from "./AdminDashboard.vue";
import AppLayoutDefault from "./AppLayoutDefault.vue";
import UserDashboard from "./UserDashboard.vue";
import UserLogin from "./UserLogin.vue";

export default defineComponent({
  setup() {
    interface State {
      layout: any;
    }

    const route: RouteLocationNormalizedLoaded = useRoute();
    const state: State = reactive({
      layout: null,
    });

    watch(
      () => route,
      (route) => {
        //if route name doesn't exist then don't proceed as it will cause the route to fire twice which causes a flash of two different layouts
        if (!route.name) return;
        try {
          //Vite currently doesn't support dynamically importing in files like import("./{route.meta.layout}.vue") in production
          switch (route.meta.layout) {
            case "AdminDashboard":
              state.layout = shallowRef(AdminDashboard);
              break;
            case "UserLogin":
              state.layout = shallowRef(UserLogin);
              break;
            case "UserDashboard":
              state.layout = shallowRef(UserDashboard);
              break;
            case "MomentumSupport":
              state.layout = shallowRef(MomentumSupport);
              break;
            case "GlobalMomentumSupport":
              state.layout = shallowRef(GlobalMomentumSupport);
              break;
            default:
              state.layout = shallowRef(AppLayoutDefault);
          }
        } catch {
          state.layout = shallowRef(AppLayoutDefault);
        }
      },
      { deep: true, immediate: true }
    );

    return {
      state,
    };
  },
});
</script>
