<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9 17C8.45 17 7.97933 16.804 7.588 16.412C7.196 16.0207 7 15.55 7 15C7 14.45 7.196 13.979 7.588 13.587C7.97933 13.1957 8.45 13 9 13C9.55 13 10.0207 13.1957 10.412 13.587C10.804 13.979 11 14.45 11 15C11 15.55 10.804 16.0207 10.412 16.412C10.0207 16.804 9.55 17 9 17ZM15 17C14.45 17 13.9793 16.804 13.588 16.412C13.196 16.0207 13 15.55 13 15C13 14.45 13.196 13.979 13.588 13.587C13.9793 13.1957 14.45 13 15 13C15.55 13 16.021 13.1957 16.413 13.587C16.8043 13.979 17 14.45 17 15C17 15.55 16.8043 16.0207 16.413 16.412C16.021 16.804 15.55 17 15 17ZM9 11C8.45 11 7.97933 10.804 7.588 10.412C7.196 10.0207 7 9.55 7 9C7 8.45 7.196 7.97933 7.588 7.588C7.97933 7.196 8.45 7 9 7C9.55 7 10.0207 7.196 10.412 7.588C10.804 7.97933 11 8.45 11 9C11 9.55 10.804 10.0207 10.412 10.412C10.0207 10.804 9.55 11 9 11ZM15 11C14.45 11 13.9793 10.804 13.588 10.412C13.196 10.0207 13 9.55 13 9C13 8.45 13.196 7.97933 13.588 7.588C13.9793 7.196 14.45 7 15 7C15.55 7 16.021 7.196 16.413 7.588C16.8043 7.97933 17 8.45 17 9C17 9.55 16.8043 10.0207 16.413 10.412C16.021 10.804 15.55 11 15 11Z" fill="currentColor" />
  </svg>
</template>
