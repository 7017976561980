/* eslint-disable no-unused-vars */
import { TSelectOption } from "../select/types";

export enum FilterEnum {
  ACCOUNT = "ACCOUNT",
  AI_LICENSE = "AI_LICENSE",
  AI_LICENSE_SETTINGS = "AI_LICENSE_SETTINGS",
  CATEGORY = "CATEGORY",
  CREATED_BY = "CREATED_BY",
  DATE = "DATE",
  FLOW_OBJECT = "FLOW_OBJECT",
  HOST = "HOST",
  INTEGRATION = "INTEGRATION",
  IS_ARCHIVED = "IS_ARCHIVED",
  OBJECT = "OBJECT",
  ORGANIZATIONS = "ORGANIZATIONS",
  PARTICIPANT = "PARTICIPANT",
  PLAYLIST = "PLAYLIST",
  ROLE = "ROLE",
  SALESFORCE_ROLE = "SALESFORCE_ROLE",
  SEARCH = "SEARCH",
  SEARCH_ID = "SEARCH_ID",
  STATUS = "STATUS",
  TAGS = "TAGS",
  TRIGGER = "TRIGGER",
  WORKFLOW = "WORKFLOW",
  WORKFLOW_TYPES = "WORKFLOW_TYPES",
}

export type TFilterConfig = {
  datePicker?: TFilterConfigDatePicker;
  disabled?: boolean;
  dropdowns?: TFilterConfigDropdown[];
  search?: TFilterConfigSearch;
  searchId?: TFilterConfigSearchId;
  workflowTypes?: TFilterConfigWorkflowTypes;
};

export type TFilterConfigDatePicker = {
  label: string;
  pickerType: "date" | "daterange";
  type: FilterEnum;
  value: number | [number, number];
};

export type TFilterConfigDropdown = {
  label: string;
  options: TSelectOption[];
  selectedOptions: TSelectOption[];
  showOnlyCurrentSelection?: boolean;
  type: FilterEnum;
};

export type TWorkflowTypes = "all" | "customer" | "internal";

export type TFilterConfigWorkflowTypes = {
  label: string;
  options: TSelectOption<TWorkflowTypes>[];
  type: FilterEnum;
  value: TWorkflowTypes;
};

export type TFilterConfigSearch = {
  label: string;
  type: FilterEnum;
  value: string;
};

export type TFilterConfigSearchId = {
  label: string;
  type: FilterEnum;
  value: number;
};

export type TActiveFilterTag = {
  displayValue: string | number;
  exclude?: boolean;
  label: string;
  momentumUserId?: number;
  type: FilterEnum;
  value: string | number | [number, number] | boolean;
};
