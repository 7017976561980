<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.44348 6.08897C8.90431 5.60925 9.54568 5.31219 10.2542 5.31219C11.199 5.31219 12.0178 5.83704 12.4586 6.61908C12.85 6.44413 13.2739 6.35381 13.7026 6.35403C15.4031 6.35403 16.7835 7.7449 16.7835 9.46118C16.7835 11.1775 15.4031 12.5683 13.7026 12.5683C13.4947 12.5683 13.2921 12.5474 13.0937 12.508C12.7079 13.1955 11.9705 13.6627 11.1308 13.6627C10.7889 13.6633 10.4513 13.5858 10.144 13.4359C9.753 14.3544 8.84238 15 7.78216 15C6.67471 15 5.73522 14.3019 5.37307 13.3205C5.21188 13.3545 5.04759 13.3716 4.88285 13.3715C3.56546 13.3715 2.5 12.2903 2.5 10.9624C2.5 10.0701 2.97972 9.29333 3.69143 8.87345C3.54044 8.52562 3.46272 8.15043 3.46311 7.77125C3.46311 6.23866 4.70702 5 6.23961 5C7.13711 5 7.94015 5.42776 8.44401 6.0917" />
  </svg>
</template>
