<template>
  <CModal :size="showAlertBanner ? 'medium' : 'small'" type="modal" :show="state.confirmingAction">
    <template v-if="showAlertBanner" #secondary-header>
      <CAlert
        :type="state.alertType"
        :title="state.alertMessage"
      />
    </template>
    <h2 class="font-semibold" v-text="state.actionMessage" />
    <p v-show="state.actionDescription" class="text-gray-500 text-xs mt-1" v-text="state.actionDescription" />
    <template #footer>
      <div class="flex items-center justify-end space-x-4" :class="showAlertBanner && 'mt-3'">
        <CButton type="button" name="secondaryButton" @click="cancelAction">
          Cancel
        </CButton>
        <CButton type="button" name="primaryButton" @click="proceedWithAction">
          Confirm
        </CButton>
      </div>
    </template>
  </CModal>
</template>

<script lang="ts" setup>
import { computed } from "vue";

import CAlert from "../alert/CAlert.vue";
import CButton from "../button/CButton.vue";
import CModal from "../modal/CModal.vue";
import { useConfirmActionStore } from "./store";

const { cancelAction, proceedWithAction, state } = useConfirmActionStore();
const showAlertBanner = computed(() => Boolean(state.alertType && state.alertMessage));
</script>
