export const playbook = {
  actions: {
    setData({ commit }, { non_zero_opportunity, zero_opportunity }) {
      if (non_zero_opportunity) {
        return commit("updateNonZero", non_zero_opportunity);
      }

      return commit("updateZero", zero_opportunity);
    },
    setPlaybook({ commit }, playbook) {
      commit("setPlaybook", playbook);
    },
  },
  mutations: {
    setPlaybook(state, playbook) {
      state.playbook = playbook;
    },
    updateNonZero(state, data) {
      if (!state.non_zero_opportunity.length) {
        state.playbook = data[0];
      }

      state.non_zero_opportunity = data;
    },
    updateZero(state, data) {
      state.zero_opportunity = data;
    },
  },
  state: () => ({
    non_zero_opportunity: [],
    playbook: null,
    zero_opportunity: [],
  }),
};
