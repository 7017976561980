<template>
  <svg
    width="40"
    height="41"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      y="0.5"
      width="40"
      height="40"
      rx="20"
      fill="#D2D5DB"
    />
    <path d="M19.1667 28.6045L13.3333 25.2503C13.0694 25.0975 12.8646 24.8962 12.7188 24.6462C12.5729 24.3962 12.5 24.1184 12.5 23.8128V17.1878C12.5 16.8823 12.5729 16.6045 12.7188 16.3545C12.8646 16.1045 13.0694 15.9031 13.3333 15.7503L19.1667 12.3962C19.4306 12.2434 19.7083 12.167 20 12.167C20.2917 12.167 20.5694 12.2434 20.8333 12.3962L26.6667 15.7503C26.9306 15.9031 27.1354 16.1045 27.2812 16.3545C27.4271 16.6045 27.5 16.8823 27.5 17.1878V23.8128C27.5 24.1184 27.4271 24.3962 27.2812 24.6462C27.1354 24.8962 26.9306 25.0975 26.6667 25.2503L20.8333 28.6045C20.5694 28.7573 20.2917 28.8337 20 28.8337C19.7083 28.8337 19.4306 28.7573 19.1667 28.6045ZM19.1667 20.9795V26.6878L20 27.167L20.8333 26.6878V20.9795L25.8333 18.0837V17.2087L24.9375 16.6878L20 19.542L15.0625 16.6878L14.1667 17.2087V18.0837L19.1667 20.9795Z" fill="#10172A" />
  </svg>
</template>
