<template>
  <span class="--small-loader" :class="[...renderSizeClasses(), renderBorderColorClasses()]" />
</template>

<script lang="ts" setup>
import { PropType } from "vue";
const props = defineProps({
  borderColor: {
    default: "white",
    type: String as PropType<"white" | "black">,
  },
  size: {
    default: "large",
    type: String as PropType<"small" | "medium" | "large">,
  },
});

function renderSizeClasses(): string[] {
  if (props.size === "small") {
    return ["w-[12px]", "h-[12px]", "border-[1.5px]"];
  } else if (props.size === "medium") {
    return ["w-[16px]", "h-[16px]"]; // still need to test this but currently not in the scope of this PR
  } else if (props.size === "large") {
    return ["w-[20px]", "h-[20px]"]; //still need to test this but currently not in the scope of this PR
  }
  console.error("Invalid size prop for CInternalLoader component");
  return [];
}

function renderBorderColorClasses(): string[] {
  if (props.borderColor === "white") {
    return ["border-white"];
  } else if (props.borderColor === "black") {
    return ["border-black"];
  }

  console.error("Invalid borderColor prop for CInternalLoader component");
  return [];
}
</script>

<style scoped>
.--small-loader {
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
