<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="currentColor"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M4.5 5.25C4.5 4.007 8.253 3 12 3s7.5 1.007 7.5 2.25v11.25C19.5 18.493 15.747 19.5 12 19.5s-7.5-1.007-7.5-2.25V5.25z"
    />
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M19.5 7.5C19.5 8.743 15.747 9.75 12 9.75S4.5 8.743 4.5 7.5M19.5 11.25C19.5 12.493 15.747 13.5 12 13.5s-7.5-1.007-7.5-2.25"
    />
  </svg>
</template>
