<template>
  <svg
    id="Layer_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 16 16"
    xml:space="preserve"
    fill="currentColor"
  >
    <path
      d="M6.2,0C5.5,0,4.9,0.6,4.9,1.2v3.7c0,0.7,0.6,1.2,1.2,1.2h1.2v1.2H4.3c-1,0-1.8,0.8-1.8,1.8v0.6H1.2C0.6,9.8,0,10.4,0,11.1
  v3.7C0,15.4,0.6,16,1.2,16h3.7c0.7,0,1.2-0.6,1.2-1.2v-3.7c0-0.7-0.6-1.2-1.2-1.2H3.7V9.2c0-0.3,0.3-0.6,0.6-0.6h7.4
  c0.3,0,0.6,0.3,0.6,0.6v0.6h-1.2c-0.7,0-1.2,0.6-1.2,1.2v3.7c0,0.7,0.6,1.2,1.2,1.2h3.7c0.7,0,1.2-0.6,1.2-1.2v-3.7
  c0-0.7-0.6-1.2-1.2-1.2h-1.2V9.2c0-1-0.8-1.8-1.8-1.8H8.6V6.2h1.2c0.7,0,1.2-0.6,1.2-1.2V1.2c0-0.7-0.6-1.2-1.2-1.2H6.2z M6.2,1.2
  h3.7v3.7H6.2V1.2z M1.2,11.1h3.7v3.7H1.2V11.1z M11.1,11.1h3.7v3.7h-3.7V11.1z"
    />
  </svg>
</template>
