<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    enable-background="new 0 0 512 512"
    xml:space="preserve"
  ><g><path
    d="M256,48C141.1,48,48,141.1,48,256s93.1,208,208,208c114.9,0,208-93.1,208-208S370.9,48,256,48z M299.4,337.1c7.5,7.5,7.5,19.8,0,27.3c-3.8,3.8-8.7,5.6-13.6,5.6c-4.9,0-9.9-1.9-13.7-5.7l-94-94.3c-6.9-7.6-6.7-19.3,0.6-26.6l95.4-95.7c7.5-7.5,19.7-7.6,27.3,0c7.5,7.5,7.6,19.7,0,27.3L219.5,256L299.4,337.1z"
    fill="currentColor"
  /></g></svg>
</template>
