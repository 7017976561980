import { defineStore } from "pinia";
import { reactive } from "vue";

import { TAlertType } from "../alert/types";

export const useConfirmActionStore = defineStore("confirm-action", () => {
  const state = reactive({
    actionDescription: "",
    actionMessage: "",
    alertMessage: "",
    alertType: null,
    confirmingAction: false,
    isConfirmed: false,
  });

  function setConfirmingAction(
    message: string,
    options?: { alertMessage?: string; alertType?: TAlertType; description?: string }
  ): void {
    state.actionMessage = message;
    state.actionDescription = options?.description || "";
    state.alertType = options?.alertType || null;
    state.alertMessage = options?.alertMessage || "";
    state.confirmingAction = true;
  }

  function cancelAction(): void {
    state.isConfirmed = false;
    state.confirmingAction = false;
  }

  function proceedWithAction(): void {
    state.isConfirmed = true;
    state.confirmingAction = false;
  }

  return { cancelAction, proceedWithAction, setConfirmingAction, state };
});
