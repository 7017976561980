<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14.6923 18C14.05 18 13.5048 17.776 13.0568 17.3279C12.6087 16.8798 12.3847 16.3346 12.3847 15.6923C12.3847 15.05 12.6087 14.5048 13.0568 14.0567C13.5048 13.6087 14.05 13.3846 14.6923 13.3846C15.3346 13.3846 15.8798 13.6087 16.3279 14.0567C16.776 14.5048 17 15.05 17 15.6923C17 16.3346 16.776 16.8798 16.3279 17.3279C15.8798 17.776 15.3346 18 14.6923 18ZM5.30773 21.5C4.8026 21.5 4.37503 21.325 4.02503 20.975C3.67503 20.625 3.50003 20.1974 3.50003 19.6923V6.30772C3.50003 5.80259 3.67503 5.37502 4.02503 5.02502C4.37503 4.67502 4.8026 4.50002 5.30773 4.50002H6.69236V3.15384C6.69236 2.93463 6.76576 2.75162 6.91256 2.60482C7.05934 2.45804 7.24235 2.38464 7.46158 2.38464C7.6808 2.38464 7.86381 2.45804 8.01061 2.60482C8.15739 2.75162 8.23078 2.93463 8.23078 3.15384V4.50002H15.8077V3.13462C15.8077 2.9218 15.8795 2.7436 16.0231 2.60002C16.1666 2.45643 16.3448 2.38464 16.5577 2.38464C16.7705 2.38464 16.9487 2.45643 17.0923 2.60002C17.2359 2.7436 17.3077 2.9218 17.3077 3.13462V4.50002H18.6923C19.1974 4.50002 19.625 4.67502 19.975 5.02502C20.325 5.37502 20.5 5.80259 20.5 6.30772V19.6923C20.5 20.1974 20.325 20.625 19.975 20.975C19.625 21.325 19.1974 21.5 18.6923 21.5H5.30773ZM5.30773 20H18.6923C18.7692 20 18.8397 19.9679 18.9039 19.9038C18.968 19.8397 19 19.7692 19 19.6923V10.3077H5.00001V19.6923C5.00001 19.7692 5.03206 19.8397 5.09616 19.9038C5.16027 19.9679 5.2308 20 5.30773 20ZM5.00001 8.80774H19V6.30772C19 6.23079 18.968 6.16026 18.9039 6.09614C18.8397 6.03204 18.7692 5.99999 18.6923 5.99999H5.30773C5.2308 5.99999 5.16027 6.03204 5.09616 6.09614C5.03206 6.16026 5.00001 6.23079 5.00001 6.30772V8.80774Z" fill="#4B5563" />
  </svg>
</template>
