<template>
  <main class="w-full flex justify-center overflow-y-auto h-full focus:outline-none" tabindex="0">
    <div class="py-4 w-full">
      <div class="px-4 sm:px-6 md:px-8">
        <div class="flex items-center mx-20">
          <NPopover v-if="!store.state.showSideBar" trigger="hover">
            <template #trigger>
              <CButton type="button" name="textPlainButton" class="-ml-3" @click="toggleNav">
                <MIcon icon="IconPanelLeftExpand" class="w-6 h-6" />
              </CButton>
            </template>
            <span>Expand Sidebar</span>
          </NPopover>
          <h1 class="text-2xl font-bold" v-text="store.state.header" />
        </div>
        <!-- body content -->
        <div class="py-4 mx-20">
          <slot />
        </div>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { NPopover } from "naive-ui";
import { defineComponent } from "vue";
import { Store, useStore } from "vuex";

import MIcon from "../../components/reusable-components/MIcon.vue";
import { CButton } from "../library";

export default defineComponent({
  components: {
    CButton,
    MIcon,
    NPopover,
  },
  setup() {
    const store: Store<any> = useStore();

    function toggleNav(): void {
      store.dispatch("toggleSideBar", !store.state.showSideBar);
    }
    return {
      store,
      toggleNav,
    };
  },
});
</script>
