<template>
  <svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1.91667 13.6667C1.58333 13.6667 1.29167 13.5417 1.04167 13.2917C0.791668 13.0417 0.666668 12.75 0.666668 12.4167V1.58337C0.666668 1.25004 0.791668 0.958374 1.04167 0.708374C1.29167 0.458374 1.58333 0.333374 1.91667 0.333374H16.0833C16.4167 0.333374 16.7083 0.458374 16.9583 0.708374C17.2083 0.958374 17.3333 1.25004 17.3333 1.58337V8.54171C16.9861 8.37504 16.6285 8.25004 16.2604 8.16671C15.8924 8.08337 15.5139 8.04171 15.125 8.04171C13.7083 8.04171 12.5069 8.53476 11.5208 9.52087C10.5347 10.507 10.0417 11.7084 10.0417 13.125V13.6667H1.91667ZM9 6.02087L2.72917 2.08337C2.53472 1.97226 2.3507 1.97226 2.17708 2.08337C2.00347 2.19449 1.91667 2.34726 1.91667 2.54171C1.91667 2.63893 1.94097 2.72574 1.98958 2.80212C2.0382 2.87851 2.09722 2.93754 2.16667 2.97921L8.66667 7.06254C8.77778 7.13199 8.88889 7.16671 9 7.16671C9.11111 7.16671 9.22222 7.13199 9.33334 7.06254L15.8333 2.97921C15.9167 2.93754 15.9792 2.87851 16.0208 2.80212C16.0625 2.72574 16.0833 2.63893 16.0833 2.54171C16.0833 2.33337 15.9965 2.17712 15.8229 2.07296C15.6493 1.96879 15.4653 1.97226 15.2708 2.08337L9 6.02087ZM15.7708 13.6667H11.9167C11.7361 13.6667 11.5868 13.6077 11.4688 13.4896C11.3507 13.3716 11.2917 13.2223 11.2917 13.0417C11.2917 12.8612 11.3507 12.7118 11.4688 12.5938C11.5868 12.4757 11.7361 12.4167 11.9167 12.4167H15.7708L14.6667 11.3125C14.5417 11.1875 14.4826 11.0417 14.4896 10.875C14.4965 10.7084 14.5625 10.5625 14.6875 10.4375C14.8125 10.3125 14.9583 10.25 15.125 10.25C15.2917 10.25 15.4375 10.3125 15.5625 10.4375L17.7292 12.6042C17.8542 12.7292 17.9167 12.875 17.9167 13.0417C17.9167 13.2084 17.8542 13.3542 17.7292 13.4792L15.5625 15.6459C15.4375 15.7709 15.2917 15.8334 15.125 15.8334C14.9583 15.8334 14.8125 15.7709 14.6875 15.6459C14.5625 15.5209 14.5035 15.375 14.5104 15.2084C14.5174 15.0417 14.5764 14.8959 14.6875 14.7709L15.7708 13.6667Z" fill="currentColor" />
  </svg>
</template>
