// This reflects the data structure we receive from Salesforce for Object Metadata and can be switched out
// for customer specific object metadata for Salesforce.
// We're reusing the data structure for Momentum, Stripe and others, since it means we can generate
// bindings in a consistent way.
import { ApolloProvider } from "@vue/apollo-option";
import pluralize from "pluralize";

import {
  IntegrationFieldTypeEnum,
  IntegrationServiceEnum,
  TIntegrationFieldMetadata,
  TIntegrationObjectLabel,
  TIntegrationObjectMetadata,
  TIntegrationObjectName,
  TIntegrationsObjectsMetadata,
} from "../types/integrations";
import { fetchObjectsMetadata } from "./fetchObjectMetadata";

export class IntegrationObjectMetadataController {
  private static objects: Partial<TIntegrationsObjectsMetadata> = {};
  private static apolloProvider: ApolloProvider<unknown> | null = null;
  private static unavailableObjects: Partial<Record<IntegrationServiceEnum, Set<TIntegrationObjectName>>> = {};
  private static objectLabelOverrides: Partial<
    Record<IntegrationServiceEnum, Record<TIntegrationObjectName, TIntegrationObjectLabel>>
  > = {
    [IntegrationServiceEnum.SALESFORCE]: {
      SBQQ__Quote__c: "CPQ Quote",
    },
  };
  private static unsupportedObjects: Partial<Record<IntegrationServiceEnum, TIntegrationObjectName[]>> = {
    // See ticket ENG-2808
    [IntegrationServiceEnum.SALESFORCE]: ["ContentDocument", "ContentNote"],
  };

  static initializeApolloProvider(apolloProvider: ApolloProvider<unknown>): void {
    this.apolloProvider = apolloProvider;
  }

  static resetObjectCache(): void {
    this.objects = {} as TIntegrationsObjectsMetadata;
    this.unavailableObjects = {} as Record<IntegrationServiceEnum, Set<TIntegrationObjectName>>;
  }

  // It's more efficient to pre-fetch objects in bulk than on-demand, assuming you're not pre-fetching the world.
  static async fetchObjects(
    service: IntegrationServiceEnum,
    objectNames: TIntegrationObjectName[],
    options?: { depth?: number; refresh?: boolean }
  ): Promise<void> {
    const depth = options?.depth ?? 1;
    const refresh = options?.refresh ?? false;
    if (!this.objects[service]) {
      this.objects[service] = {};
    }
    const objectsToFetch: TIntegrationObjectName[] = refresh
      ? objectNames
      : this.filterNonFetchedObjectNames(service, objectNames);
    let toFetchNextObjectNames: TIntegrationObjectName[] = [];
    const objectsMetadata: TIntegrationObjectMetadata[] = await this.retrieveObjectsMetadata(service, objectsToFetch, {
      refresh,
    });
    const retrievedObjectNames: Set<TIntegrationObjectName> = new Set<TIntegrationObjectName>();
    objectsMetadata.forEach((objectMetadata: TIntegrationObjectMetadata): void => {
      if (objectMetadata === undefined) {
        throw new Error(`Got undefined while fetching objectNames ${JSON.stringify(objectNames)}`);
      }
      this.objects[service][objectMetadata.name] = objectMetadata;
      objectMetadata.fields.forEach((field) => {
        if (field.referenceTo && field.referenceTo.length > 0) {
          toFetchNextObjectNames = toFetchNextObjectNames.concat(field.referenceTo);
        }
      });
      retrievedObjectNames.add(objectMetadata?.name);
    });
    this.recordUnavailableObjects(service, objectsToFetch, retrievedObjectNames);
    toFetchNextObjectNames = this.filterNonFetchedObjectNames(service, toFetchNextObjectNames);
    if (toFetchNextObjectNames.length > 0 && depth > 0) {
      // Don't pass on "refresh", as we only want to refresh the top-level objects for now
      await this.fetchObjects(service, toFetchNextObjectNames, { depth: depth - 1 });
    }
  }

  static async getObject(
    service: IntegrationServiceEnum,
    objectName: TIntegrationObjectName,
    options?: { refresh?: boolean }
  ): Promise<TIntegrationObjectMetadata> {
    const refresh = options?.refresh ?? false;
    if (this.unavailableObjects[service]?.has(objectName)) {
      return null;
    }
    const isLoaded = Boolean(this.getObjectFromLocalCache(service, objectName));
    if (!refresh && !isLoaded) {
      console.warn(
        `Service ${service} object ${objectName} is not initialized. ` +
          `Call fetchObjects proactively with multiple object names for efficiency`
      );
    }
    if (refresh) {
      console.warn(`Service ${service} object ${objectName} refresh requested`);
    }
    if (refresh || !isLoaded) {
      // Fetch single object (inefficient)
      await this.fetchObjects(service, [objectName], { refresh });
    }
    const object = this.getObjectFromLocalCache(service, objectName);
    if (!object) {
      // Log error if we're requesting objects that are not found on the backend.
      // Unless it's Quote, which is explicit pre-fetch, but may not be available for all Salesforce instances.
      if (!(service === IntegrationServiceEnum.SALESFORCE && objectName === "Quote")) {
        console.error(
          `IntegrationObjectMetadataController.getObjectFields: ${service} object metadata ` +
            `for "${objectName}" was not retrieved`
        );
      }
      return null;
    }
    return object;
  }

  private static getObjectFromLocalCache(
    service: IntegrationServiceEnum,
    objectName: TIntegrationObjectName
  ): TIntegrationObjectMetadata {
    return this.objects?.[service]?.[objectName];
  }

  static async getObjectFields(
    service: IntegrationServiceEnum,
    objectName: TIntegrationObjectName
  ): Promise<TIntegrationFieldMetadata[]> {
    const object = await this.getObject(service, objectName);
    if (object === null) {
      return null;
    }
    return object.fields;
  }

  static async getObjectLabel(
    service: IntegrationServiceEnum,
    objectName: TIntegrationObjectName,
    options?: { plural?: boolean }
  ): Promise<TIntegrationObjectLabel> {
    const object: TIntegrationObjectMetadata = await this.getObject(service, objectName);
    if (object === null) {
      return null;
    }
    if (options?.plural) {
      return object.labelPlural ?? pluralize(object.label);
    }
    return object.label;
  }

  static filterNonFetchedObjectNames(
    service: IntegrationServiceEnum,
    objectNames: TIntegrationObjectName[]
  ): TIntegrationObjectName[] {
    const serviceObjects = this.objects[service];
    return objectNames.filter((objectName) => !serviceObjects[objectName]);
  }

  private static recordUnavailableObjects(
    service: IntegrationServiceEnum,
    requestedObjectNames: TIntegrationObjectName[],
    retrievedObjectNames: Set<TIntegrationObjectName>
  ): void {
    const failedObjectNames = requestedObjectNames.filter((name) => !retrievedObjectNames.has(name));
    failedObjectNames.forEach((objectName) => {
      if (!this.unavailableObjects[service]) {
        this.unavailableObjects[service] = new Set<TIntegrationObjectName>();
      }
      this.unavailableObjects[service].add(objectName);
    });
  }

  private static async retrieveObjectsMetadata(
    service: IntegrationServiceEnum,
    objectNames: string[],
    options?: { refresh?: boolean }
  ): Promise<TIntegrationObjectMetadata[]> {
    const refresh: boolean = options?.refresh ?? false;
    let objectsMetadata: TIntegrationObjectMetadata[] = await fetchObjectsMetadata(
      this.apolloProvider,
      service,
      objectNames,
      { refresh }
    );
    if (objectsMetadata) {
      objectsMetadata = objectsMetadata.map((objectMetadata: TIntegrationObjectMetadata) => {
        let updated: TIntegrationObjectMetadata = this.applyLabelOverride(service, objectMetadata);
        updated = this.stripUnsupportedReferenceToObjects(service, updated);
        return updated;
      });
    }
    return objectsMetadata;
  }

  static applyLabelOverride(
    service: IntegrationServiceEnum,
    objectMetadata: TIntegrationObjectMetadata
  ): TIntegrationObjectMetadata {
    const objectLabelOverride = this.objectLabelOverrides[service]?.[objectMetadata.name];
    if (objectLabelOverride) {
      return {
        ...objectMetadata,
        label: objectLabelOverride,
      };
    }
    return objectMetadata;
  }

  private static stripUnsupportedReferenceToObjects(
    service: IntegrationServiceEnum,
    objectMetadata: TIntegrationObjectMetadata
  ): TIntegrationObjectMetadata {
    return {
      ...objectMetadata,
      fields: objectMetadata?.fields
        .map(
          (field: TIntegrationFieldMetadata): TIntegrationFieldMetadata => ({
            ...field,
            referenceTo: this.removeUnsupportedReferenceToObjects(service, field.referenceTo),
          })
        )
        // Remove reference fields that no longer have any referenceTo entries.
        // This may result in objects that have no fields.
        .filter(({ referenceTo, type }) => !(type === IntegrationFieldTypeEnum.reference && referenceTo?.length === 0)),
    };
  }

  private static removeUnsupportedReferenceToObjects(
    service: IntegrationServiceEnum,
    referenceTo: TIntegrationObjectName[]
  ): TIntegrationObjectName[] {
    if (referenceTo?.length > 0) {
      return referenceTo.filter((o: TIntegrationObjectName) => !this.unsupportedObjects[service]?.includes(o));
    }
    return referenceTo;
  }
}
