<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.222566 7.132L3.62175 3.73281C3.8106 3.54396 4.03316 3.40908 4.28945 3.32814C4.54574 3.24721 4.80877 3.23372 5.07855 3.28768L6.63651 3.57094C5.89462 4.40725 5.29437 5.19972 4.83575 5.94835C4.37713 6.69698 3.95223 7.57038 3.56105 8.56856L0.222566 7.132ZM4.30969 8.93276C4.67388 7.94807 5.1325 7.02071 5.68555 6.15068C6.23859 5.28065 6.86582 4.49493 7.56724 3.79351C8.75426 2.60649 10.1099 1.7196 11.6341 1.13283C13.1584 0.546068 14.5814 0.367341 15.9033 0.596651C16.1327 1.91856 15.9573 3.34163 15.3773 4.86587C14.7973 6.39011 13.9137 7.74574 12.7267 8.93276C12.0253 9.63418 11.2362 10.2614 10.3594 10.8145C9.48266 11.3675 8.55193 11.8261 7.56724 12.1903L4.30969 8.93276ZM10.0964 6.4036C10.3662 6.67338 10.7 6.80826 11.0979 6.80826C11.4959 6.80826 11.8297 6.67338 12.0995 6.4036C12.3693 6.13382 12.5042 5.79997 12.5042 5.40205C12.5042 5.00413 12.3693 4.67028 12.0995 4.40051C11.8297 4.13073 11.4959 3.99584 11.0979 3.99584C10.7 3.99584 10.3662 4.13073 10.0964 4.40051C9.82662 4.67028 9.69173 5.00413 9.69173 5.40205C9.69173 5.79997 9.82662 6.13382 10.0964 6.4036ZM9.38824 16.2774L7.93144 12.9389C8.92962 12.5478 9.80302 12.1229 10.5516 11.6642C11.3003 11.2056 12.0927 10.6054 12.9291 9.86349L13.2123 11.4214C13.2663 11.6912 13.2528 11.9576 13.1719 12.2207C13.0909 12.4837 12.956 12.7096 12.7672 12.8985L9.38824 16.2774ZM1.51749 11.5631C1.9896 11.091 2.56288 10.8515 3.23732 10.8448C3.91176 10.8381 4.48504 11.0707 4.95715 11.5428C5.42926 12.015 5.66531 12.5882 5.66531 13.2627C5.66531 13.9371 5.42926 14.5104 4.95715 14.9825C4.60644 15.3332 4.06014 15.6232 3.31826 15.8525C2.57637 16.0818 1.47028 16.2977 0 16.5C0.202333 15.0297 0.418154 13.927 0.647465 13.1919C0.876775 12.4567 1.16679 11.9138 1.51749 11.5631Z" fill="currentColor" />
  </svg>
</template>
