<template>
  <svg
    width="52"
    height="52"
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_853_35862)">
      <rect
        x="10"
        y="6"
        width="32"
        height="32"
        rx="16"
        fill="#19212C"
      />
      <path d="M20.6667 28C20.3 28 19.9862 27.8694 19.725 27.6083C19.4639 27.3472 19.3334 27.0333 19.3334 26.6667V22H20.6667V26.6667H26.6667V28H20.6667ZM23.3334 25.3333C22.9667 25.3333 22.6528 25.2028 22.3917 24.9417C22.1306 24.6806 22 24.3667 22 24V19.3333H23.3334V24H29.3334V25.3333H23.3334ZM26 22.6667C25.6334 22.6667 25.3195 22.5361 25.0584 22.275C24.7973 22.0139 24.6667 21.7 24.6667 21.3333V17.3333C24.6667 16.9667 24.7973 16.6528 25.0584 16.3917C25.3195 16.1306 25.6334 16 26 16H31.3334C31.7 16 32.0139 16.1306 32.275 16.3917C32.5362 16.6528 32.6667 16.9667 32.6667 17.3333V21.3333C32.6667 21.7 32.5362 22.0139 32.275 22.275C32.0139 22.5361 31.7 22.6667 31.3334 22.6667H26ZM26 21.3333H31.3334V18.6667H26V21.3333Z" fill="white" />
    </g>
    <defs>
      <filter
        id="filter0_d_853_35862"
        x="0"
        y="0"
        width="52"
        height="52"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_853_35862" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_853_35862" result="shape" />
      </filter>
    </defs>
  </svg>
</template>
