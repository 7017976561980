//NOTE: reason for doing this is there's a complicated issue/configuration with importing env vars in TS files. I (Silas) haven't found a good solution yet so I'm doing this for the time being
export const auth0ClientId = import.meta.env.VITE_AUTH0_CLIENT_ID;
export const auth0DefaultTenantDomain = import.meta.env.VITE_AUTH0_DEFAULT_TENANT_DOMAIN;
export const auth0Domain = import.meta.env.VITE_AUTH0_DOMAIN;
export const isLocalDevEnvironment: boolean =
  import.meta.env.VITE_IS_LOCAL_DEV === "true" || import.meta.env.VITE_ENVIRONMENT?.startsWith("local-dev-");
export const momentumApiUrl = import.meta.env.VITE_API_URL;
export const salesforceAuthUrl = import.meta.env.VITE_SALESFORCE_AUTH_URL;
export const baseUrl = import.meta.env.VITE_BASE_URL;
export const environment = import.meta.env.VITE_ENVIRONMENT;
