<template>
  <svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5.7635 11.675C5.63017 11.675 5.50517 11.654 5.3885 11.612C5.27183 11.5707 5.1635 11.5 5.0635 11.4L0.763502 7.1C0.580168 6.91667 0.492502 6.679 0.500502 6.387C0.509168 6.09567 0.605168 5.85833 0.788501 5.675C0.971835 5.49167 1.20517 5.4 1.4885 5.4C1.77183 5.4 2.00517 5.49167 2.1885 5.675L5.7635 9.25L14.2385 0.775C14.4218 0.591667 14.6595 0.5 14.9515 0.5C15.2428 0.5 15.4802 0.591667 15.6635 0.775C15.8468 0.958333 15.9385 1.19567 15.9385 1.487C15.9385 1.779 15.8468 2.01667 15.6635 2.2L6.4635 11.4C6.3635 11.5 6.25517 11.5707 6.1385 11.612C6.02183 11.654 5.89683 11.675 5.7635 11.675Z" fill="currentColor" />
  </svg>
</template>
