import "./assets/css/index.css";
import "tippy.js/dist/tippy.css";

import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, NormalizedCacheObject, split } from "@apollo/client/core";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { IdToken } from "@auth0/auth0-vue";
import * as Sentry from "@sentry/vue";
import { ApolloClients } from "@vue/apollo-composable";
import { ApolloProvider, createApolloProvider } from "@vue/apollo-option";
import { FragmentDefinitionNode, OperationDefinitionNode } from "graphql/index";
import { createPinia } from "pinia";
import { App, createApp, watchEffect } from "vue";
//vue tippy
import VueTippy from "vue-tippy";

import MomentumApp from "./App.vue";
import { auth0 } from "./auth0";
//fetch integrations object metadata
import { IntegrationObjectMetadataController } from "./integrations/objectMetadata";
import { getRouters } from "./router";
import { initializeSegment } from "./segment/utils";
import store from "./store";
import { isSupportConsole } from "./utils/is-support-console";
import { toggleSideBar } from "./utils/mobile";
import { initPushNotifications } from "./utils/push-notifications";

//initialize vue app
const app: App<Element> = createApp(MomentumApp);
const pinia = createPinia();

// variables
const env: Record<string, string> = import.meta.env;
const siteProfile: string = env.VITE_SITE_PROFILE;
const environmentUrl: string = isSupportConsole()
  ? // backwards compatible, so all engineers do not need to add env vars immediately
    env.VITE_ENVIRONMENT_URL_SUPPORT || env.VITE_ENVIRONMENT_URL
  : env.VITE_ENVIRONMENT_URL;

app.provide("isSupportConsole", siteProfile === "support-console");

addEventListener("resize", (): void => {
  toggleSideBar();
});

toggleSideBar();

app.use(
  VueTippy,
  // optional
  {
    component: "tippy", // => <tippy/>
    componentSingleton: "tippy-singleton", // => <tippy-singleton/>,
    defaultProps: {
      allowHTML: true,
      placement: "auto-end",
    }, // => Global default options * see all props
    directive: "tippy", // => v-tippy
  }
);

//when user is authenticated, initialize vue apollo configuration
watchEffect(async (): Promise<void> => {
  if (auth0?.isAuthenticated?.value && auth0?.idTokenClaims?.value) {
    initPushNotifications(auth0.idTokenClaims.value.__raw);
    await initApollo();
  } else if (!store.state.apolloPublic) {
    await initApolloPublic();
  }
});

setInterval(async (): Promise<void> => {
  await signUserOut();
}, 1000);

async function signUserOut(): Promise<void> {
  if (!auth0?.isAuthenticated?.value && !auth0?.idTokenClaims?.value) return;
  const profile = store.state.profile;
  const now = Math.floor(new Date().getTime() / 1000);
  const tokenExpiration: number = auth0.idTokenClaims.value.exp;
  const isExpired: boolean = now >= tokenExpiration;

  if (isExpired && profile) {
    await store.dispatch("validations/hasMadeChanges", false);
    await auth0.logout();
  }
}

async function initApollo(options?: { refreshToken: string }): Promise<void> {
  const claims: IdToken = auth0.idTokenClaims.value;
  const token: string = claims.__raw;
  const headers = {
    authorization: `Bearer ${options?.refreshToken || token}`,
  };
  const graphCMSHeaders = {
    authorization: `Bearer ${env.VITE_GRAPHCMS_READ_TOKEN}`,
  };
  const cache = new InMemoryCache();
  const httpLink = new HttpLink({
    headers,
    // You should use an absolute URL here
    uri: ({ operationName }): string => {
      return `${env.VITE_GRAPH_QL_URL_HTTP}?gqlOp=${operationName}`;
    },
  });

  const graphCMSLink: HttpLink = new HttpLink({
    headers: graphCMSHeaders,
    uri: env.VITE_GRAPHCMS_ENDPOINT,
  });

  const wsLink: WebSocketLink = new WebSocketLink({
    options: {
      connectionParams: {
        headers,
      },
      reconnect: true,
    },
    uri: env.VITE_GRAPH_QL_URL_WS,
  });

  const link: ApolloLink = split(
    ({ query }) => {
      const definition: OperationDefinitionNode | FragmentDefinitionNode = getMainDefinition(query);
      return definition.kind === "OperationDefinition" && definition.operation === "subscription";
    },
    wsLink,
    httpLink
  );

  const apolloClient: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    cache,
    link,
  });

  const graphcms: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    cache,
    link: graphCMSLink,
  });

  const apolloProvider: ApolloProvider = createApolloProvider({
    clients: {
      graphcms,
    },
    defaultClient: apolloClient,
  });

  IntegrationObjectMetadataController.initializeApolloProvider(apolloProvider);

  app.use(apolloProvider);
  app.provide("apollo", apolloProvider);
  //leaving this here until we migrate the useQueries off the other Vue 3 Composition APIs
  app.provide(ApolloClients, {
    default: apolloClient,
  });

  await store.dispatch("setApollo", apolloProvider);
  await store.dispatch("setAuthUser", claims);

  //Linking segment snippet to start tracking on different environments
  initializeSegment(env.VITE_SEGMENT_WRITE_KEY);
}

async function initApolloPublic(): Promise<void> {
  const cache = new InMemoryCache();
  const httpLink = new HttpLink({
    uri: ({ operationName }): string => {
      return `${env.VITE_GRAPH_QL_URL_HTTP}?gqlOp=${operationName}`;
    },
  });

  const wsLink: WebSocketLink = new WebSocketLink({
    options: {
      reconnect: true,
    },
    uri: env.VITE_GRAPH_QL_URL_WS,
  });

  const link: ApolloLink = split(
    ({ query }) => {
      const definition: OperationDefinitionNode | FragmentDefinitionNode = getMainDefinition(query);
      return definition.kind === "OperationDefinition" && definition.operation === "subscription";
    },
    wsLink,
    httpLink
  );

  const apolloClient: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    cache,
    link,
  });

  const apolloProvider: ApolloProvider = createApolloProvider({
    defaultClient: apolloClient,
  });

  IntegrationObjectMetadataController.initializeApolloProvider(apolloProvider);

  app.use(apolloProvider);
  app.provide("apolloPublic", apolloProvider);
  await store.dispatch("setApolloPublic", apolloProvider);
  initializeSegment(env.VITE_SEGMENT_WRITE_KEY);
}

Sentry.init({
  app,
  beforeSend(event) {
    if (event.user) {
      event.user = { id: event.user?.id };
    }
    return event;
  },
  dsn: "https://5777719dccac6acb233a39362a12564d@o4506719196348416.ingest.us.sentry.io/4506719221317632",
  environment: env.VITE_ENVIRONMENT,
  integrations: [
    Sentry.browserTracingIntegration({ router: getRouters().router }),
    Sentry.replayIntegration({
      blockAllMedia: false,
      maskAllText: false,
    }),
    Sentry.httpContextIntegration(),
    //we don't want to submit database queries to Sentry or xhr requests as it could be sensitive data
    Sentry.breadcrumbsIntegration({ fetch: false, xhr: false }),
  ],
  release: [`fe-${siteProfile}`, env.VITE_PACKAGE_VERSION].join("@"),
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [environmentUrl],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
});

app.use(store);
app.use(getRouters().router);
//need to create auth0 plugin after the router configuration or unexpected behavior will occur
app.use(auth0);
app.use(pinia);
app.mount("#app");
