import { ApolloProvider } from "@vue/apollo-option";
import { useStore } from "vuex";

import { IntegrationsApi } from "../api/integrations/api";
import { IntegrationServiceEnum } from "../types/integrations";

export async function isOrganizationIntegrationAuthenticated(
  apollo: ApolloProvider,
  service: IntegrationServiceEnum
): Promise<boolean> {
  const store = useStore();
  const integrationsApi = new IntegrationsApi(apollo);
  let isAuthenticated = store.getters["integrations/isOrganizationIntegrationAuthenticated"](service);
  if (isAuthenticated !== undefined) {
    return isAuthenticated;
  }
  const organizationIntegrationIsAuthenticated = await integrationsApi.isServiceConnectedForOrganization(service);
  isAuthenticated = Boolean(organizationIntegrationIsAuthenticated?.[0]);
  store.dispatch("integrations/setIsOrganizationIntegrationAuthenticated", {
    isOrganizationIntegratedWithService: isAuthenticated,
    service,
  });
  return isAuthenticated;
}
