<template>
  <Teleport to="body">
    <div class="absolute bottom-[70px] sm:left-[20px] sm:justify-start flex left-0 right-0 justify-center">
      <TransitionGroup name="list" tag="ul" class="pop-up-notifications-container w-full sm:w-auto" style="z-index: 1000">
        <li v-for="notification in storeState.notifications" :key="notification.id" class="sm:w-96 sm:px-0 px-4">
          <AppNotification :notification="notification" />
        </li>
      </TransitionGroup>
    </div>
  </Teleport>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { useNotificationsStore } from "../../store/notifications";
import AppNotification from "./AppNotification.vue";

export default defineComponent({
  components: {
    AppNotification,
  },
  setup() {
    const { state: storeState } = useNotificationsStore();

    return {
      storeState,
    };
  },
});
</script>

<style>
.list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
  transition: opacity 0.5s cubic-bezier(0.55, 0, 0.1, 1), transform 0.5s cubic-bezier(0.55, 0, 0.1, 1),
    top 0.5s cubic-bezier(0.55, 0, 0.1, 1);
  position: relative;
  z-index: 1000;
}

.list-enter-from,
.list-leave-to {
  opacity: 90%;
  position: relative;
  z-index: 1000;
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-leave-active {
  position: absolute;
  transition: opacity 1s ease, transform 1s ease, top 1s ease;
  z-index: 1000;
}
</style>
