<template>
  <div class="w-6 h-6 rounded-full flex items-center justify-center bg-cyan-400 text-white" :class="customClass" v-text="name?.charAt(0)?.toUpperCase()" />
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    customClass: {
      default: "",
      type: String,
    },
    name: {
      default: "",
      type: String,
    },
  },
});
</script>
