import { createAuthGuard } from "@auth0/auth0-vue";
import { App } from "vue";
import { RouteRecordRaw } from "vue-router";

export function createUserAppRoutes(app: App): RouteRecordRaw[] {
  const authGuard = createAuthGuard(app);

  return [
    // Account Briefs
    {
      beforeEnter: authGuard,
      component: () => import("../pages/AccountBriefs.vue"),
      meta: {
        layout: "UserDashboard",
        title: "Account Briefs",
      },
      name: "account-briefs",
      path: "/account-briefs",
    },
    {
      beforeEnter: authGuard,
      component: () => import("../pages/AccountBriefView.vue"),
      meta: {
        layout: "UserDashboard",
        title: "Account Brief View",
      },
      name: "account-brief-view",
      path: "/account-briefs/:id",
    },
    // Ask Momentum
    {
      beforeEnter: authGuard,
      component: () => import("../pages/AskMomentum.vue"),
      meta: {
        layout: "UserDashboard",
        title: "Ask Momentum",
      },
      name: "ask-momentum-page",
      path: "/ask-momentum",
    },
    {
      beforeEnter: authGuard,
      component: () => import("../pages/AskMomentum.vue"),
      meta: {
        layout: "UserDashboard",
        title: "Ask Momentum Conversation",
      },
      name: "ask-momentum-conversation",
      path: "/ask-momentum/:id",
    },

    // Call Library/Meetings
    {
      beforeEnter: authGuard,
      component: () => import("../pages/UserCallLibrary.vue"),
      meta: {
        layout: "UserDashboard",
        title: "Meetings",
      },
      name: "meetings",
      path: "/meetings",
    },
    {
      beforeEnter: authGuard,
      children: [
        {
          component: () => import("../pages/meeting-details/DataInsights.vue"),
          meta: {
            layout: "UserDashboard",
            title: "Data Insights",
          },
          name: "meeting-data-insights",
          path: "data-insights",
        },
        {
          beforeEnter: authGuard,
          component: () => import("../pages/meeting-details/AdditionalInfo.vue"),
          meta: {
            layout: "UserDashboard",
            title: "Meeting Info",
          },
          name: "meeting-additional-info",
          path: "meeting-info",
        },
        {
          beforeEnter: authGuard,
          component: () => import("../pages/meeting-details/ActionItems.vue"),
          meta: {
            layout: "UserDashboard",
            title: "Action Items",
          },
          name: "meeting-action-items",
          path: "action-items",
        },
        {
          beforeEnter: authGuard,
          component: () => import("../pages/meeting-details/SendEmail.vue"),
          meta: {
            layout: "UserDashboard",
            title: "Send Email",
          },
          name: "meeting-send-email",
          path: "send-email",
        },
        {
          beforeEnter: authGuard,
          component: () => import("../pages/meeting-details/CoachingCompetency.vue"),
          meta: {
            layout: "UserDashboard",
            title: "Coaching",
          },
          name: "meeting-coaching",
          path: "coaching",
        },
      ],
      component: () => import("../pages/meeting-details/MeetingEdit.vue"),
      meta: {
        layout: "UserDashboard",
        title: "Meetings",
      },
      name: "meeting",
      path: "/meeting/:id",
    },
    {
      beforeEnter: authGuard,
      component: () => import("../pages/UserCallLibrary.vue"),
      meta: {
        layout: "UserDashboard",
        title: "Call Library",
      },
      name: "user-call-library",
      path: "/call-library/:tab?",
    },
    // Call Library - Playlists
    {
      beforeEnter: authGuard,
      component: () => import("../pages/UserPlaylistDetails.vue"),
      meta: {
        layout: "UserDashboard",
        title: "Playlist Details",
      },
      name: "user-playlist-details",
      path: "/call-library/playlists/:id",
    },
    // Autopilot
    {
      beforeEnter: authGuard,
      component: () => import("../pages/AutoPilot.vue"),
      meta: {
        layout: "UserDashboard",
        title: "Autopilot",
      },
      name: "autopilot",
      path: "/autopilot",
    },
    // Email Follow-Up
    {
      beforeEnter: authGuard,
      component: () => import("../pages/EmailFollowUpView.vue"),
      meta: {
        layout: "UserDashboard",
        title: "Email Follow-Up",
      },
      name: "email-follow-up",
      path: "/email-follow-up",
    },
    {
      beforeEnter: authGuard,
      component: () => import("../pages/EmailFollowUpUserConfig.vue"),
      meta: {
        layout: "UserDashboard",
        title: "Email Follow-Up Config",
      },
      name: "user-email-follow-up-config",
      path: "/email-follow-up/config",
    },
    // Integrations
    {
      beforeEnter: authGuard,
      component: () => import("../pages/ZoomUserSettings.vue"),
      meta: {
        layout: "UserDashboard",
        title: "Settings Zoom",
      },
      name: "zoom-settings",
      path: "/zoom-settings",
    },
    {
      beforeEnter: authGuard,
      component: () => import("../pages/MyIntegrations.vue"),
      meta: {
        layout: "UserDashboard",
        title: "Individual Account Connect",
      },
      name: "individual-account-connect",
      path: "/individual-account-connect",
    },
  ];
}
