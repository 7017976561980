import { TMeeting } from "../api/meeting/types";
import { TEmailFollowUpEmailSuggestion } from "../types/email-follow-up";
interface State {
  emailSuggestion: {
    all: TEmailFollowUpEmailSuggestion[];
    expired: TEmailFollowUpEmailSuggestion[];
    "expiring-soon": TEmailFollowUpEmailSuggestion[];
  };
  meeting: {
    [key: number]: TMeeting;
  };
  showCreateEmail: boolean;
}
export const emailFollowUp = {
  actions: {
    setEmailSuggestion({ commit }, data): void {
      commit("setEmailSuggestion", data);
    },
    setMeeting({ commit }, data): void {
      commit("setMeeting", data);
    },
    setShowCreateEmail({ commit }, data): void {
      commit("setShowCreateEmail", data);
    },
  },

  mutations: {
    setEmailSuggestion(state, data): void {
      state.emailSuggestion = data;
    },
    setMeeting(state, data): void {
      state.meeting = data;
    },
    setShowCreateEmail(state, data): void {
      state.showCreateEmail = data;
    },
  },

  state: () =>
    <State>{
      emailSuggestion: null,
      meeting: null,
      showCreateEmail: false,
    },
};
