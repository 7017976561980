<template>
  <p :class="[renderHeadingStyling(size)]" v-text="title" />
</template>

<script lang="ts" setup>
import { PropType } from "vue";

import { THeadingSize } from "./types";
defineProps({
  size: {
    default: "small",
    type: String as PropType<THeadingSize>,
  },
  title: {
    default: "",
    required: true,
    type: String,
  },
});

function renderHeadingStyling(size: THeadingSize): string {
  const classes: string[] = ["font-medium"];
  switch (size) {
    case "x-small":
      classes.push("text-sm");
      break;
    case "small":
      classes.push("text-lg");
      break;
    case "medium":
      classes.push("text-xl");
      break;
    case "large":
      classes.push("text-2xl");
      break;
  }

  return classes.join(" ");
}
</script>
