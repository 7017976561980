import { IntegrationServiceEnum, ServiceStatusEnum } from "../../types/integrations";

export type TGetTeamMembersVariables = {
  integrationWhere?: {
    _and: {
      service: {
        _in: string[];
      };
      status: {
        _eq: ServiceStatusEnum;
      };
    };
  };
  limit: number;
  offset: number;
  roleWhere:
    | {
        _in: UserRoleEnum[];
      }
    | {};
  salesforceRoleWhere:
    | {
        _in: number[];
      }
    | {};
  searchTerm: string;
  userLicenseWhere: {
    _or?: {
      [key: string]: {
        _eq: boolean;
      };
    }[];
  };
};

export type TTeamMemberBuildConfig = {
  aiLicenseSettingsLength?: number;
  integrationsLength: number;
  variables: TGetTeamMembersVariables;
};

export type TLimitOffsetVariables = {
  limit: number;
  offset: number;
};

export type TUser = {
  deleted_at?: Date | null;
  email: string;
  fullName: string;
  hasCompletedSignup?: boolean;
  hasLicenseAssigned?: boolean;
  id: number;
  integrations?: TUserIntegration[];
  isExternal: boolean;
  label?: string; // invalid user field
  name: string; // deprecated, do not use
  picture: string;
  role: UserRoleEnum;
  salesforceUserRole?: {
    name: string;
  };
  slackUserId: string;
  slackUserName: string;
  slackUserProfile: {
    real_name: string;
  };
  type: TUserType;
  userId?: number;
  userInvitations?: {
    [key: string]: string;
  }[];
  userLicense?: TAiLicenseConstraints;
};

export type TUserIntegration = {
  service: IntegrationServiceEnum;
  status: string;
  useForOrganization?: boolean;
};

export type TTeamUser = {
  salesforceUserRoleName?: string;
} & TUser;

export type TSelectUserOption = {
  disabled: boolean;
  id: string | number;
  label: string;
  momentumUser: TUser | null;
  momentumUserId: number | null;
  type: "user" | "binding";
  value: string | number | null;
};

export type TAuthUser = {
  allowedRoles: AccessRoleEnum[];
  email: string;
  isMomentumSupport: boolean;
  name: string;
  organizationId: number;
  picture: string;
  role: AccessRoleEnum;
  userId?: number | null;
};

export type TMomentumUser = {
  email: string;
  fullName: string;
  id: number;
};

export type TUserCount = {
  admins: number;
  aiLicensesAvailable: number;
  aiLicensesProvisioned: number;
  aiLicensesUsed: number;
  allUsers: number;
  editors: number;
  userAdmins: number;
  users: number;
};

export enum UserInvitationStatusEnum {
  // eslint-disable-next-line no-unused-vars
  PENDING = "pending",
}

export enum TeamActionsEnum {
  // eslint-disable-next-line no-unused-vars
  ASSIGN_AI_LICENSE_SETTINGS = "ASSIGN_AI_LICENSE_SETTINGS",
  // eslint-disable-next-line no-unused-vars
  INVITE = "INVITE",
  // eslint-disable-next-line no-unused-vars
  MAKE_ADMIN = "MAKE_ADMIN",
  // eslint-disable-next-line no-unused-vars
  MAKE_EDITOR = "MAKE_EDITOR",
  // eslint-disable-next-line no-unused-vars
  MAKE_USER = "MAKE_USER",
  // eslint-disable-next-line no-unused-vars
  MAKE_USER_ADMIN = "MAKE_USER_ADMIN",
  // eslint-disable-next-line no-unused-vars
  REMOVE_AI_LICENSE_SETTINGS = "REMOVE_AI_LICENSE_SETTINGS",
  // eslint-disable-next-line no-unused-vars
  REMOVE_USER = "REMOVE_USER",
  // eslint-disable-next-line no-unused-vars
  RESEND_INVITE = "RESEND_INVITE",
  // eslint-disable-next-line no-unused-vars
  UPDATE_AI_LICENSE_SETTINGS = "UPDATE_AI_LICENSE_SETTINGS",
  // eslint-disable-next-line no-unused-vars
  UPDATE_ROLE = "UPDATE_ROLE",
}

export enum UserRoleEnum {
  // eslint-disable-next-line no-unused-vars
  EDITOR = "editor",
  // eslint-disable-next-line no-unused-vars
  ORGANIZATION_ADMIN = "organization-admin",
  // eslint-disable-next-line no-unused-vars
  USER = "user",
  // eslint-disable-next-line no-unused-vars
  USER_ADMIN = "user-admin",
}

export type TUserType = "service-account" | "slack-user" | "standard";

export enum SupportRoleEnum {
  // eslint-disable-next-line no-unused-vars
  MOMENTUM_SUPPORT = "momentum-support",
}

export type AccessRoleEnum = UserRoleEnum | SupportRoleEnum;

export enum EmailSourceEnum {
  // eslint-disable-next-line no-unused-vars
  SALESFORCE = "SALESFORCE",
}

export const emailSourceNames: Record<EmailSourceEnum, string> = {
  [EmailSourceEnum.SALESFORCE]: "Salesforce",
};

export enum VideoCallSourceEnum {
  // eslint-disable-next-line no-unused-vars
  CHORUS = "CHORUS",
  // eslint-disable-next-line no-unused-vars
  CLARI_COPILOT = "CLARI_COPILOT",
  // eslint-disable-next-line no-unused-vars
  GONG = "GONG",
  // eslint-disable-next-line no-unused-vars
  MOMENTUM_NATIVE = "MOMENTUM_NATIVE",
  // eslint-disable-next-line no-unused-vars
  WISER = "WISER",
}

export const videoCallSourceNames: Record<VideoCallSourceEnum, string> = {
  [VideoCallSourceEnum.CHORUS]: "Chorus",
  [VideoCallSourceEnum.CLARI_COPILOT]: "Clari Copilot",
  [VideoCallSourceEnum.GONG]: "Gong",
  [VideoCallSourceEnum.MOMENTUM_NATIVE]: "Momentum Recorder",
  [VideoCallSourceEnum.WISER]: "Wiser",
};

export type TMomentumRecorderSettings = {
  botRecordExternallyHostedMeetings?: boolean;
  botRecordNonZoomSources?: boolean;
  botRecordZoomSources?: boolean;
  googleMeetNativeEnabled?: boolean;
  zoomNativeEnabled?: boolean;
};

export type TUserLicenseConstraints = {
  chorusEnabled: boolean;
  emailImport?: boolean;
  gongEnabled: boolean;
  momentumRecording: TMomentumRecorderSettings;
  salesloftEnabled?: boolean;
  textMessageChatImport?: boolean;
  wingmanEnabled: boolean;
  wiserEnabled: boolean;
};

export type TAiLicenseConstraints = {
  emailImport?: boolean;
  meetingBotRecordExternallyHostedMeetings?: boolean;
  meetingBotRecordNonZoomSources?: boolean;
  meetingBotRecordZoomSources?: boolean;
  meetingImportAndRecordGoogleCalendarSources?: boolean;
  meetingImportAndRecordZoomSources?: boolean;
  meetingImportChorusSources?: boolean;
  meetingImportGongSources?: boolean;
  meetingImportSalesloftSources?: boolean;
  meetingImportWingmanSources?: boolean;
  meetingImportWiserSources?: boolean;
  textMessageChatImport?: boolean;
};

export enum UserAiLicenseSettingTypeEnum {
  // eslint-disable-next-line no-unused-vars
  CHORUS = "CHORUS",
  // eslint-disable-next-line no-unused-vars
  EMAIL = "EMAIL",
  // eslint-disable-next-line no-unused-vars
  GONG = "GONG",
  // eslint-disable-next-line no-unused-vars
  MOMENTUM = "MOMENTUM",
  // eslint-disable-next-line no-unused-vars
  SALESLOFT = "SALESLOFT",
  // eslint-disable-next-line no-unused-vars
  TEXT_MESSAGE_CHAT = "TEXT_MESSAGE_CHAT",
  // eslint-disable-next-line no-unused-vars
  WINGMAN = "WINGMAN",
  // eslint-disable-next-line no-unused-vars
  WISER = "WISER",
}

export type TVideoCallSource = {
  [key: string]: string;
};

export type TAiLicenseSettingsForm = {
  emailSettings: TEmailSettings;
  momentumRecorderSettings: TMomentumRecorderSettings;
  salesloftSettings: TSalesloftSettings;
  textMessageChatSettings: TTextMessageChatSettings;
  videoCallSource: string;
};

export type TEmailSettings = {
  emailImport: boolean;
};

export type TTextMessageChatSettings = {
  textMessageChatImport: boolean;
};

export type TSalesloftSettings = {
  salesloftEnabled: boolean;
};

export type TAllSteps = {
  [stepName: string]: {
    args?: TStepArgs;
    type: string;
  };
};

export type TStepReceiver = {
  slackChannelId?: string;
  slackUserId?: string;
  slackUserIds?: string[];
};

export type TStepArgs = {
  receiver?: TStepReceiver;
  slackChannelId?: string;
  slackUserIds?: string[];
  splitByUserField: string;
};
