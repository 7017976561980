<template>
  <div class="relative pl-4 rounded-md" :class="{ 'bg-mm-colors-sidebarHighlight': routeContains(route.name) }">
    <router-link :to="{ name: defaultRoute }">
      <button type="button" class="sidebar-button w-full flex items-center justify-between" :class="{ 'text-mm-colors-textPrimary': routeContains(route.name), 'text-gray-600': !routeContains(route.name) }" @click="state.isOpen = !state.isOpen">
        <CBadge :show-badge="betaBadge" display-flex>
          <div class="flex items-center">
            <p class="mr-2 w-6">
              <slot name="icon" />
            </p>
            <p v-text="buttonName" />
          </div>
        </CBadge>
      </button>
    </router-link>
    <div v-if="state.isOpen">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, watch } from "vue";
import { useRoute } from "vue-router";

import { CBadge } from "../library";

export default defineComponent({
  components: {
    CBadge,
  },
  props: {
    betaBadge: {
      default: false,
      type: Boolean,
    },
    buttonName: {
      default: "",
      require: true,
      type: String,
    },
    defaultRoute: {
      default: "",
      require: true,
      type: String,
    },
    namedRoutes: {
      default: () => [],
      type: Array,
    },
  },
  setup(props) {
    const route: any = useRoute();
    const state = reactive({
      isOpen: <boolean>false,
    });

    onMounted(() => {
      if (routeContains(route.name)) {
        state.isOpen = true;
      }
    });

    watch(
      () => route.name,
      (newValue) => {
        state.isOpen = routeContains(newValue);
      }
    );

    function routeContains(routeName: string): boolean {
      return props.namedRoutes.includes(routeName);
    }

    return {
      route,
      routeContains,
      state,
    };
  },
});
</script>
