<template>
  <svg
    width="12"
    height="11"
    viewBox="0 0 12 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.75 10.8333V1.38334C0.75 1.09459 0.8529 0.847318 1.0587 0.641518C1.26415 0.436068 1.51125 0.333344 1.8 0.333344H10.2C10.4887 0.333344 10.736 0.436068 10.9418 0.641518C11.1473 0.847318 11.25 1.09459 11.25 1.38334V7.68334C11.25 7.97209 11.1473 8.21937 10.9418 8.42517C10.736 8.63062 10.4887 8.73334 10.2 8.73334H2.85L0.75 10.8333ZM1.8 8.30022L2.41688 7.68334H10.2V1.38334H1.8V8.30022Z" fill="currentColor" />
  </svg>
</template>
