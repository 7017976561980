<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    enable-background="new 0 0 512 512"
    xml:space="preserve"
  ><g><path
    d="M48,256c0,114.9,93.1,208,208,208c114.9,0,208-93.1,208-208S370.9,48,256,48C141.1,48,48,141.1,48,256z M292.5,256l-81.9-81.1c-7.5-7.5-7.5-19.8,0-27.3c7.5-7.5,19.8-7.5,27.3,0l95.4,95.7c7.3,7.3,7.5,19.1,0.6,26.6l-94,94.3c-3.8,3.8-8.7,5.7-13.7,5.7c-4.9,0-9.9-1.9-13.6-5.6c-7.5-7.5-7.6-19.7,0-27.3L292.5,256z"
    fill="currentColor"
  /></g></svg>
</template>
