import { defineStore } from "pinia";
import { v4 as uuidv4 } from "uuid";
import { reactive } from "vue";

import { TNotification } from "../types/notification";

type TState = {
  notifications: TNotification[];
};

export const useNotificationsStore = defineStore("notifications", () => {
  const state: TState = reactive({
    notifications: [],
  });

  function addNotification(notification: TNotification): void {
    state.notifications.push(notification);
  }

  function removeNotification(id: string): void {
    state.notifications = state.notifications.filter((notification: TNotification) => notification.id !== id);
  }

  function setSuccessMessage(message: string): void {
    const notification: TNotification = {
      id: uuidv4(),
      message,
      severity: "info",
    };
    addNotification(notification);
  }

  function setErrorMessage(message: string): void {
    const notification: TNotification = {
      id: uuidv4(),
      message,
      severity: "error",
    };

    if (state.notifications.some((notification) => notification.message === message)) {
      return;
    }
    addNotification(notification);
  }

  function setWarningMessage(message: string): void {
    const notification: TNotification = {
      id: uuidv4(),
      message,
      severity: "warning",
    };
    addNotification(notification);
  }

  return { removeNotification, setErrorMessage, setSuccessMessage, setWarningMessage, state };
});
