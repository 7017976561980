<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M11.5114 4.37553C11.6402 4.50816 11.803 4.57447 12 4.57447C12.197 4.57447 12.3598 4.50816 12.4886 4.37553C12.6174 4.24291 12.6818 4.07518 12.6818 3.87234V2.70213C12.6818 2.49929 12.6174 2.33156 12.4886 2.19894C12.3598 2.06631 12.197 2 12 2C11.803 2 11.6402 2.06631 11.5114 2.19894C11.3826 2.33156 11.3182 2.49929 11.3182 2.70213V3.87234C11.3182 4.07518 11.3826 4.24291 11.5114 4.37553Z" fill="currentColor" />
    <path d="M19.6932 11.8011C19.822 11.9337 19.9848 12 20.1818 12H21.3182C21.5152 12 21.678 11.9337 21.8068 11.8011C21.9356 11.6684 22 11.5007 22 11.2979C22 11.095 21.9356 10.9273 21.8068 10.7947C21.678 10.6621 21.5152 10.5957 21.3182 10.5957H20.1818C19.9848 10.5957 19.822 10.6621 19.6932 10.7947C19.5644 10.9273 19.5 11.095 19.5 11.2979C19.5 11.5007 19.5644 11.6684 19.6932 11.8011Z" fill="currentColor" />
    <path d="M2.19318 11.8011C2.32197 11.9337 2.48485 12 2.68182 12H3.81818C4.01515 12 4.17803 11.9337 4.30682 11.8011C4.43561 11.6684 4.5 11.5007 4.5 11.2979C4.5 11.095 4.43561 10.9273 4.30682 10.7947C4.17803 10.6621 4.01515 10.5957 3.81818 10.5957H2.68182C2.48485 10.5957 2.32197 10.6621 2.19318 10.7947C2.06439 10.9273 2 11.095 2 11.2979C2 11.5007 2.06439 11.6684 2.19318 11.8011Z" fill="currentColor" />
    <path d="M17.2727 6.13085C17.2727 6.32589 17.3409 6.49362 17.4773 6.63404C17.6136 6.77447 17.7765 6.84468 17.9659 6.84468C18.1553 6.84468 18.3106 6.78227 18.4318 6.65745L18.9773 6.09574C19.1136 5.95532 19.1818 5.78759 19.1818 5.59255C19.1818 5.39752 19.1136 5.22979 18.9773 5.08936C18.8409 4.94894 18.6742 4.88262 18.4773 4.89043C18.2803 4.89823 18.1212 4.96454 18 5.08936L17.4773 5.62766C17.3409 5.76809 17.2727 5.93582 17.2727 6.13085Z" fill="currentColor" />
    <path d="M5.02273 6.09574L5.56818 6.63404C5.70455 6.77447 5.86742 6.84468 6.05682 6.84468C6.24621 6.84468 6.40909 6.77447 6.54545 6.63404C6.68182 6.49362 6.74621 6.32589 6.73864 6.13085C6.73106 5.93582 6.66667 5.77589 6.54545 5.65106L6 5.08936C5.86364 4.94894 5.70076 4.87872 5.51136 4.87872C5.32197 4.87872 5.15909 4.94894 5.02273 5.08936C4.88636 5.22979 4.82197 5.40142 4.82955 5.60426C4.83712 5.80709 4.90152 5.97092 5.02273 6.09574Z" fill="currentColor" />
    <path d="M10.9483 21.5398C11.2551 21.8466 11.6305 22 12.0744 22C12.5183 22 12.8936 21.8466 13.2004 21.5398C13.5073 21.233 13.6607 20.8576 13.6607 20.4137H10.4881C10.4881 20.8576 10.6415 21.233 10.9483 21.5398Z" fill="currentColor" />
    <path d="M9.06825 19.033C9.17923 19.144 9.31958 19.1995 9.4893 19.1995H14.6594C14.8292 19.1995 14.9695 19.144 15.0805 19.033C15.1915 18.9221 15.247 18.7817 15.247 18.612C15.247 18.4423 15.1915 18.3019 15.0805 18.1909C14.9695 18.08 14.8292 18.0245 14.6594 18.0245H9.4893C9.31958 18.0245 9.17923 18.08 9.06825 18.1909C8.95728 18.3019 8.90179 18.4423 8.90179 18.612C8.90179 18.7817 8.95728 18.9221 9.06825 19.033Z" fill="currentColor" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.9532 14.7246C7.45585 15.5667 8.13802 16.2685 8.99971 16.8299H15.149C16.0238 16.2685 16.7092 15.5667 17.2053 14.7246C17.7015 13.8825 17.9495 12.9327 17.9495 11.8752C17.9495 10.2823 17.3685 8.90494 16.2066 7.74296C15.0446 6.58099 13.6672 6 12.0744 6C10.4816 6 9.10416 6.58099 7.94218 7.74296C6.78021 8.90494 6.19922 10.2823 6.19922 11.8752C6.19922 12.9327 6.45054 13.8825 6.9532 14.7246ZM14.7378 15.6548H9.43055C8.79081 15.237 8.28816 14.6952 7.9226 14.0294C7.55703 13.3635 7.37425 12.6455 7.37425 11.8752C7.37425 10.5826 7.83447 9.47613 8.75491 8.55569C9.67535 7.63525 10.7818 7.17503 12.0744 7.17503C13.3669 7.17503 14.4734 7.63525 15.3938 8.55569C16.3143 9.47613 16.7745 10.5826 16.7745 11.8752C16.7745 12.6455 16.5917 13.3635 16.2261 14.0294C15.8606 14.6952 15.3645 15.237 14.7378 15.6548Z" fill="currentColor" />
  </svg>
</template>
